import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './recognition-leaderboard.loaders';
import { converters } from './recognition-leaderboard.converters';
import { RecognitionLeaderboardFilters } from './recognition-leaderboard.types';

export const recognitionLeaderboardKeys = queryKeyFactory(
  'recognition-leaderboard',
);

export const useRecognitionLeaderboard = (
  filters: Partial<RecognitionLeaderboardFilters> = {},
) => {
  return useQuery({
    queryKey: recognitionLeaderboardKeys.list(filters),
    queryFn: () => loaders.getLeaderboard({ filters }),
    select: converters.getLeaderboard,
  });
};
