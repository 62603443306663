import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IEmployeesGroup } from 'types/income';
import { EmployeeGroupsFilters } from './employee-groups.types';

interface GetEmployeesGroups {
  filters: Partial<EmployeeGroupsFilters>;
}

export const loaders = {
  getEmployeesGroups: ({ filters }: GetEmployeesGroups) => {
    return api<TableDataResponse<IEmployeesGroup>>({
      url: Endpoints.clientAdmin.employeesGroups.root,
      params: {
        filter: {
          name: filters.name || undefined,
        },
      },
    });
  },
};
