import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { IBaseAdminDiscount, IAdminDiscount } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { Translations } from 'types/Common';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { FrequencyUnit } from 'types/general';
import { exportXlsxConfig } from 'utils/general';
import {
  AdminDiscountsFilters,
  DiscountPackage,
} from './admin-discounts.types';

interface GetDiscounts {
  filters: Partial<AdminDiscountsFilters>;
}

interface GetDiscount {
  discountId: string;
}

interface CreateDiscount {
  name: string;
  supplier: string;
  countries: string[];
  categories: ShopItemCategory[];
  priority: number;
  packages: DiscountPackage[];
  companies: string[];
  comment: string;
  validFrom: string | null;
  validTo: string | null;
  supplierContact: string;
  isSupplierContactPublic: boolean;
  code: string;
  link: string;
  codeImageId: string;
  isUniqueCode: boolean;
  revealFrequencyLimit: FrequencyUnit | 'none';
  translations: Translations<{
    title: string;
    teaser: string;
    promo: string;
    description: string;
    attachments: string[];
  }>;
  imageId: string;
  isDraft: boolean;
  searchTags: string;
  showCurrentTime: boolean;
  publishedAt: string | null;
}

interface UpdateDiscount {
  discountId: string;
  data: Partial<CreateDiscount>;
}

interface DeleteDiscount {
  discountId: string;
}

interface ExportXls {
  filters: Partial<AdminDiscountsFilters>;
}

export const loaders = {
  getDiscounts: ({ filters }: GetDiscounts) => {
    return api<TableDataResponse<IBaseAdminDiscount>>({
      url: Endpoints.melpAdmin.discounts.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          status: filters.status,
          validFrom: filters.validAt?.from,
          validTo: filters.validAt?.to,
          countries: filters.countries,
          supplierId: filters.suppliers,
          categories: filters.categories,
          publishStatus: filters.publishStatus,
          discountPackage: filters.package,
          viewsCountFrom: filters.viewsCount?.from,
          viewsCountTo: filters.viewsCount?.to,
          search: filters.search || undefined,
        },
        sort: filters.sort
          ? `${filters.sort.order === 'desc' ? '-' : ''}${
              filters.sort.columnKey
            }`
          : undefined,
      },
    });
  },
  getDiscount: ({ discountId }: GetDiscount) => {
    return api<IAdminDiscount>({
      url: Endpoints.melpAdmin.discounts.byId.replace(
        ':discountId',
        discountId,
      ),
    });
  },
  createDiscount: (data: CreateDiscount) => {
    return api<{ id: string }>({
      url: Endpoints.melpAdmin.discounts.root,
      method: 'post',
      data: {
        name: data.name,
        supplierId: data.supplier,
        countries: data.countries,
        categories: data.categories,
        priority: data.priority,
        internalComment: data.comment,
        validFrom: data.validFrom,
        validTo: data.validTo,
        supplierContact: data.supplierContact,
        showSupplierContactForUsers: data.isSupplierContactPublic,
        discountCode: data.code,
        discountLink: data.link,
        discountPackage: data.packages,
        discountPackageClientIds: data.companies,
        qrBarCodeImageId: data.codeImageId,
        discountImageId: data.imageId,
        isUniqueCode: data.isUniqueCode,
        revealFrequencyLimit: data.revealFrequencyLimit,
        translations: data.translations.map((trans) => ({
          language: trans.language,
          discountTitle: trans.title,
          teaserLine: trans.teaser,
          discountPromo: trans.promo,
          discountDescription: trans.description,
          attachments: trans.attachments,
        })),
        isDraft: data.isDraft,
        searchTags: data.searchTags,
        showCurrentTime: data.showCurrentTime,
        publishDateTime: data.publishedAt,
      },
    });
  },
  updateDiscount: ({ discountId, data }: UpdateDiscount) => {
    return api<{ id: string }>({
      url: Endpoints.melpAdmin.discounts.byId.replace(
        ':discountId',
        discountId,
      ),
      method: 'patch',
      data: {
        name: data.name,
        supplierId: data.supplier,
        countries: data.countries,
        categories: data.categories,
        priority: data.priority,
        internalComment: data.comment,
        validFrom: data.validFrom,
        validTo: data.validTo,
        supplierContact: data.supplierContact,
        showSupplierContactForUsers: data.isSupplierContactPublic,
        discountCode: data.code,
        discountLink: data.link,
        discountPackage: data.packages,
        discountPackageClientIds: data.companies,
        qrBarCodeImageId: data.codeImageId,
        discountImageId: data.imageId,
        isUniqueCode: data.isUniqueCode,
        revealFrequencyLimit: data.revealFrequencyLimit,
        translations: data.translations?.map((trans) => ({
          language: trans.language,
          discountTitle: trans.title,
          teaserLine: trans.teaser,
          discountPromo: trans.promo,
          discountDescription: trans.description,
          attachments: trans.attachments,
        })),
        isDraft: data.isDraft,
        searchTags: data.searchTags,
        showCurrentTime: data.showCurrentTime,
        publishDateTime: data.publishedAt,
      },
    });
  },
  deleteDiscount: ({ discountId }: DeleteDiscount) => {
    return api({
      url: Endpoints.melpAdmin.discounts.byId.replace(
        ':discountId',
        discountId,
      ),
      method: 'delete',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.melpAdmin.discounts.export.xls,
      params: {
        filter: {
          status: filters.status,
          validFrom: filters.validAt?.from,
          validTo: filters.validAt?.to,
          countries: filters.countries,
          supplierId: filters.suppliers,
          categories: filters.categories,
          publishStatus: filters.publishStatus,
          discountPackage: filters.package,
          viewsCountFrom: filters.viewsCount?.from,
          viewsCountTo: filters.viewsCount?.to,
          search: filters.search || undefined,
          sort: filters.sort
            ? `${filters.sort.order === 'desc' ? '-' : ''}${
                filters.sort.columnKey
              }`
            : undefined,
        },
      },
      ...exportXlsxConfig,
    });
  },
};
