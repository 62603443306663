import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IRecognitionLeaderboardEmployee,
  IRecognitionLeaderboardTotals,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { exportXlsxConfig, getSorting } from 'utils/general';
import { RecognitionLeaderboardFilters } from './recognition-leaderboard.types';

const getFilters = (filters: Partial<RecognitionLeaderboardFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  ...getSorting(filters.sort),
  search: filters.search || undefined,
  filter: {
    type: filters.type,
    employeeId: filters.employees,
    companyId: filters.companies,
    departmentId: filters.departments,
    dateFrom: filters.date?.from,
    dateTo: filters.date?.to,
  },
});

interface GetLeaderboard {
  filters: Partial<RecognitionLeaderboardFilters>;
}

interface ExportXls {
  filters: Partial<RecognitionLeaderboardFilters>;
}

export const loaders = {
  getLeaderboard: ({ filters }: GetLeaderboard) => {
    return api<
      TableDataResponse<IRecognitionLeaderboardEmployee> &
        IRecognitionLeaderboardTotals
    >({
      url: Endpoints.clientAdmin.recognitions.leaderboard.root,
      params: {
        ...getFilters(filters),
      },
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    const { page, pageSize, sorting, direction, ...restFilters } =
      getFilters(filters);
    return api({
      url: Endpoints.clientAdmin.recognitions.leaderboard.export.xls,
      params: {
        ...restFilters,
      },
      ...exportXlsxConfig,
    });
  },
};
