import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSWR, { mutate } from 'swr';
import create from 'zustand';
import { makeRequest } from '../api/api';
import { Endpoints } from '../api/constants';
import { useDefaultStartTime } from '../constants/Employees';
import { Status } from '../types/Common';
import {
  CreateEmployeeRequest,
  Employee,
  EmployeeResponse,
  EmployeeUpdateResponse,
  EmployeesBirthdays,
  EmployeesWorkAnniversaries,
  UpdateInnerEmployeeRequest,
  UseEmployeeStore,
  UseEmployees,
} from '../types/Employees';
import { SimulationResponse } from '../types/NotificationService';
import { useGet, useCustomSwrMutation, useXLSExport } from '../utils/Api';
import { getTimezoneFromDate, mergeDateTime } from '../utils/Common';
import { useEmployeesUtils } from '../utils/Employees';
import { useLoading } from '../utils/Loading';
import { useMe } from './Administrators';

export const useEmployeesLegacy = (
  id?: string,
  query?: string,
  preventCall = false,
): UseEmployees => {
  const { hasEmployeePermissions } = useMe();
  const { parseEmployeeResponseData, parseStatusDates } = useEmployeesUtils();
  const [userExist, setUserExist] = useState<boolean>(false);
  const { t } = useTranslation();
  const defaultStartDate = useDefaultStartTime();
  const {
    data: employeeList,
    error: employeeListError,
    mutate: mutateEmployeeTableData,
  } = useSWR(
    hasEmployeePermissions && !id && !preventCall
      ? `${Endpoints.employee}${!!query ? `?${query}` : ''}`
      : null,
  );
  const { data: employee, error: employeeError } = useSWR(
    hasEmployeePermissions && id && !preventCall
      ? `${Endpoints.employee}/${id}`
      : null,
    {
      revalidateOnFocus: false,
    },
  );

  const { stopLoading, startLoading, loading } = useLoading();

  const parsedEmployeeList = useMemo(
    () =>
      employeeList?.data?.map((data: Employee) => ({
        ...data,
        inactive: data.employmentStatus === 'ended',
        customColumn: data?.customColumn?.name ?? '-',
      })) ?? [],
    [employeeList],
  );

  const parsedEmployeeTotals = useMemo(() => {
    const initialTotals = {
      fullName: '',
      employeeGroup: '',
      employeeGroups: '',
      jobTitle: '',
      employmentStatus: '',
    };
    if (!employeeList?.footer) return initialTotals;
    const {
      fullName,
      employeeGroups,
      jobTitle,
      employmentStatus,
      customColumn,
    } = employeeList.footer;
    return {
      fullName: t('totals.employee', { count: Number(fullName) }),
      employeeGroup: t('totals.employee_group', {
        count: Number(employeeGroups),
      }),
      jobTitle: t('totals.job_title', { count: Number(jobTitle) }),
      employmentStatus: t('totals.inactive', {
        count: Number(employmentStatus),
      }),
      employeeGroups: '',
      customColumn: t('totals.custom_category', {
        count: Number(customColumn ?? 0),
      }),
    };
  }, [employeeList, t]);

  const parsedEmployeeData = useMemo<any>(
    () => parseEmployeeResponseData(employee as EmployeeResponse),
    [employee, parseEmployeeResponseData],
  );

  const parsedEmployeeStatus = useMemo(
    () =>
      parseStatusDates(
        parsedEmployeeData?.startDate,
        parsedEmployeeData?.endDate,
        parsedEmployeeData?.state,
        parsedEmployeeData?.endDateTZ,
        parsedEmployeeData?.startDateTZ,
      ),
    [
      parseStatusDates,
      parsedEmployeeData?.endDate,
      parsedEmployeeData?.endDateTZ,
      parsedEmployeeData?.startDate,
      parsedEmployeeData?.startDateTZ,
      parsedEmployeeData?.state,
    ],
  );

  const apiLoading = useMemo(() => {
    if (!!id) return !employee && !employeeError;
    return !employeeList && !employeeListError;
  }, [id, employee, employeeError, employeeList, employeeListError]);

  const checkIfUserExist = useCallback((response: any) => {
    if (response.error === 'DuplicateError') {
      return setUserExist(true);
    } else {
      return setUserExist(false);
    }
  }, []);

  const createEmployee = useCallback(
    async (data: CreateEmployeeRequest) => {
      startLoading();
      const dataEntries = Object.entries(data);
      const dataFiltered = dataEntries?.filter(
        ([key]) => !key.includes('mergeGroup'),
      );
      const parsedData = { ...Object.fromEntries(dataFiltered) };
      const startDate = mergeDateTime(
        parsedData.startDate,
        parsedData.startTime,
      );
      const startDateTZ = getTimezoneFromDate(startDate);
      const phone = parsedData.phone ? `+${parsedData.phone}` : undefined;
      delete parsedData.startTime;
      try {
        const res = await makeRequest<EmployeeResponse>(
          'post',
          Endpoints.createEmployee,
          {
            ...parsedData,
            startDate: startDate ?? defaultStartDate,
            startDateTZ,
            phone,
            email: data.email?.length ? data.email : undefined,
          },
        );
        mutate(Endpoints.employee);
        toast(t('common.added_succesfully'), { type: 'success' });
        return res.data;
      } catch (err: any) {
        checkIfUserExist(err.response.data);
      } finally {
        stopLoading();
      }
    },
    [startLoading, defaultStartDate, t, checkIfUserExist, stopLoading],
  );

  const updateInnerEmployee = useCallback(
    (data: UpdateInnerEmployeeRequest) => {
      const preProcessPhone = () => {
        if (!data.phone) {
          return null;
        }
        return data.phone.startsWith('+') ? data.phone : `+${data.phone}`;
      };
      startLoading();
      makeRequest('patch', `${Endpoints.employee}/${id}`, {
        ...data,
        capacity: !data.capacity ? 'full-time' : data.capacity,
        phone: preProcessPhone(),
      })
        .then(() => {
          toast(t('common.updated_succesfully'), { type: 'success' });
          mutate(`${Endpoints.employee}/${id}`);
        })
        .finally(() => {
          stopLoading();
        });
    },
    [startLoading, stopLoading, t, id],
  );

  const assignGroupSimulation = useCallback(
    async (
      assignEmployeeGroupIds: string[],
      removeEmployeeGroupIds: string[],
    ) => {
      startLoading();
      const responceData = await makeRequest(
        'patch',
        `${Endpoints.employee}/${id}/assignGroup/simulation`,
        {
          assignEmployeeGroupIds,
          removeEmployeeGroupIds,
        },
      )
        .then((res: any) => {
          mutate(`${Endpoints.employee}/${id}`);
          return res?.data as SimulationResponse[];
        })
        .finally(() => {
          stopLoading();
        });
      return responceData;
    },
    [startLoading, stopLoading, id],
  );

  const getExportFilterQuery = () => {
    if (!query) {
      return '';
    }

    const newQuery = query
      .split('&')
      .map((queryParam) => {
        const paramsMapping: Record<string, string> = {
          'filter[fullName]': 'filter[employeeFullName][]',
          'filter[customColumn]': 'filter[customCategoryValue][]',
          category: 'filter[customCategory]',
          'filter[employeeGroup]': 'filter[employeeGroupName][]',
          'filter[hireDateFrom]': 'filter[employmentDateFrom]',
          'filter[hireDateTo]': 'filter[employmentDateTo]',
          'filter[appUser]': 'filter[isAppUser]',
          'filter[employmentStatus]': 'filter[activationStatus][]',
          companyId: 'filter[companyId]',
          companyGroupId: 'filter[companyGroupId]',
          departmentId: 'filter[departmentId]',
        };

        const [paramName, paramValue] = queryParam.split('=');
        const newName = paramsMapping[paramName];
        if (newName) {
          return `${newName}=${paramValue}`;
        }

        return queryParam;
      })
      .join('&');

    return '?' + newQuery;
  };

  const xlsExport = useXLSExport(
    `${Endpoints.clientAdmin.employee.exportToExcel}${getExportFilterQuery()}`,
  );

  return {
    loading: loading || apiLoading,
    employee: parsedEmployeeData,
    employeeError,
    employeeList,
    employeeListError,
    createEmployee,
    checkIfUserExist,
    userExist,
    parsedEmployeeList,
    updateInnerEmployee,
    parsedEmployeeStatus,
    parsedEmployeeTotals,
    assignGroupSimulation,
    ...xlsExport,
    mutateEmployeeTableData,
  };
};

export const useEmployeeStore = create<UseEmployeeStore>((set) => ({
  status: { name: 'inactive' },
  setStatus: (obj: Status) => set(() => ({ status: obj })),
  startDate: null,
  endDate: null,
  setStartDate: (startDate: string | null, type?: 'date' | 'time') => {
    if (type === 'date' && !!startDate) {
      set((state: UseEmployeeStore) => ({
        startDate: mergeDateTime(startDate, state.startDate ?? ''),
      }));
    } else if (type === 'time' && !!startDate) {
      set((state: UseEmployeeStore) => ({
        startDate: mergeDateTime(state.startDate ?? '', startDate),
      }));
    } else set(() => ({ startDate }));
  },
  setEndDate: (endDate: string | null, type?: 'date' | 'time') => {
    if (type === 'date' && !!endDate) {
      set((state: UseEmployeeStore) => ({
        endDate: mergeDateTime(endDate, state.endDate ?? ''),
      }));
    } else if (type === 'time' && !!endDate) {
      set((state: UseEmployeeStore) => ({
        endDate: mergeDateTime(state.endDate ?? '', endDate),
      }));
    } else set(() => ({ endDate }));
  },
}));

export const useEmployeesBirthdays = () => {
  return useGet<EmployeesBirthdays>(Endpoints.clientAdmin.employee.birthdays);
};

export const useEmployeesWorkAnniversaries = () => {
  const { data, loading } = useGet<EmployeesWorkAnniversaries>(
    Endpoints.clientAdmin.employee.workAnniversaries,
  );

  return {
    data: data
      ? {
          ...data,
          today: data.today.filter(
            (anniversary) => anniversary.workingYears > 0,
          ),
        }
      : undefined,
    loading,
  };
};

export const useEmployee = (id: string) => {
  return useGet<EmployeeResponse>(Endpoints.clientAdmin.employee.byId(id).root);
};

export const useEmployeeMutations = (id: string) => {
  const url = Endpoints.clientAdmin.employee.byId(id).root;

  const update = useCustomSwrMutation<
    UpdateInnerEmployeeRequest,
    EmployeeUpdateResponse
  >(url, 'patch', {
    onSuccess: () => mutate(url),
  });

  return { update };
};
