import {
  FilterFunction,
  MpFilterProps,
  MpFilterType,
  MpFilterTypes,
  MpTableClasses,
  MpTableDataArguments,
  MpTableOverridablesMap,
  MpTableTranslations,
} from '@mp-react/table';
import { isArray } from 'lodash';
import moment from 'moment';
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Loader } from 'melp-design/components';
import { AssignmentStatus } from 'store/assignments';
import CustomSearchSelect from '../components/table/CustomFilters/CustomSearchSelectFilter/CustomSearchSelect';
import DateRange from '../components/table/CustomFilters/DateRange/DateRange';
import FilterNameSearchSelectFilter from '../components/table/CustomFilters/FilterNameSearchSelectFilter/FilterNameSearchSelectFilter';
import SelectionFilter from '../components/table/CustomFilters/SelectionFilter/SelectionFilter';
import CustomGlobalActions from '../components/table/CustomGlobalActions/CustomGlobalActions';
import AllocationFrequency from '../components/table/CustomRenderers/AllocationFrequency';
import BasicStatusPill from '../components/table/CustomRenderers/BasicStatusPill';
import BenefitAssignmentStatusCell from '../components/table/CustomRenderers/BenefitAssignmentStatusCell';
import FlexBenefitBalanceDetails from '../components/table/CustomRenderers/BenefitBalanceDetails';
import BenefitTypeTranslations from '../components/table/CustomRenderers/BenefitTypeTranslations';
import Checked from '../components/table/CustomRenderers/Checked';
import Currency from '../components/table/CustomRenderers/Currency';
import CurrencyWithFraction from '../components/table/CustomRenderers/CurrencyWithFraction';
import DateEndPill from '../components/table/CustomRenderers/DateEndPill';
import DateFormat from '../components/table/CustomRenderers/DateFormat';
import DateTime from '../components/table/CustomRenderers/DateTime';
import EmployeeGroup from '../components/table/CustomRenderers/EmployeeGroup';
import Empty from '../components/table/CustomRenderers/Empty';
import Groups from '../components/table/CustomRenderers/Groups';
import Implementation from '../components/table/CustomRenderers/Implementation';
import IncludedExcludedSwitch from '../components/table/CustomRenderers/IncludedExcludedSwitch';
import KeyLabelMap from '../components/table/CustomRenderers/KeyLabelMap';
import ProgressBarCell from '../components/table/CustomRenderers/ProgressBarCell';
import ShortMonthDate from '../components/table/CustomRenderers/ShortMonthDate';
import SimpleStringArray from '../components/table/CustomRenderers/SimpleStringArray';
import StatusPill from '../components/table/CustomRenderers/StatusPill';
import StringArray from '../components/table/CustomRenderers/StringArray';
import { Switch } from '../components/table/CustomRenderers/Switch';
import TimeRemaining from '../components/table/CustomRenderers/TimeRemaining';
import YesNo from '../components/table/CustomRenderers/YesNo';
import MpTableHeadFilter from '../components/table/HeadFilter/HeadFilter';
import HeadIndicator from '../components/table/HeadIndicator/HeadIndicator';
import NoData from '../components/table/NoData/NoData';
import Paginator from '../components/table/Paginator/Paginator';
import { noBreadcrumbs } from '../constants/ParentCompany';
import { useMe } from '../state/Administrators';
import { useInvestmentCalculationsStore } from '../state/InvestmentCalculations';
import { useNewsRecipientsStore } from '../state/NewsRecipients';
import { HeaderVariant, useTableStore } from '../state/Table';
import useStyles from '../styles/Table.styles';
import { BasicActivityStatus } from '../types/Common';
import { StorageKeys } from '../types/LocalStorage';
import {
  CountFilterValue,
  CustomRendererType,
  DateFilterValue,
  FilterItem,
  InvestmentFilterValue,
  SearchSelectFilterItem,
  SearchSelectFilterType,
} from '../types/Table';
import { useLocalStorage } from './LocalStorage';
import { isDefined } from './isDefined';
import { useCurrency } from './useCurrency';

const customFilterMap: Record<string, ComponentType<MpFilterProps>> = {
  dateRange: DateRange,
  searchSelect: CustomSearchSelect,
  filterNameSearchSelect: FilterNameSearchSelectFilter,
  selectionFilter: SelectionFilter,
};

const customFilterFunctionMap: Record<string, MpFilterTypes | FilterFunction> =
  {};

const customRendererMap: Record<
  CustomRendererType,
  ComponentType<CellProps<{}>>
> = {
  [CustomRendererType.dateEndPill]: DateEndPill,
  [CustomRendererType.statusPill]: StatusPill,
  [CustomRendererType.implementation]: Implementation,
  [CustomRendererType.timeRemaining]: TimeRemaining,
  [CustomRendererType.yesNo]: YesNo,
  [CustomRendererType.employeeGroup]: EmployeeGroup,
  [CustomRendererType.empty]: Empty,
  [CustomRendererType.dateTime]: DateTime,
  [CustomRendererType.dateFormat]: DateFormat,
  [CustomRendererType.benefitBalanceDetails]: FlexBenefitBalanceDetails,
  [CustomRendererType.groups]: Groups,
  [CustomRendererType.includedExcludedSwitch]: IncludedExcludedSwitch,
  [CustomRendererType.simpleStringArray]: SimpleStringArray,
  [CustomRendererType.stringArray]: StringArray,
  [CustomRendererType.currency]: Currency,
  [CustomRendererType.currencyWithFraction]: CurrencyWithFraction,
  [CustomRendererType.shortMonthDate]: ShortMonthDate,
  [CustomRendererType.checked]: Checked,
  [CustomRendererType.switch]: Switch,
  [CustomRendererType.benefitTypeTranslation]: BenefitTypeTranslations,
  [CustomRendererType.allocationFrequency]: AllocationFrequency,
  [CustomRendererType.progressBar]: ProgressBarCell,
  [CustomRendererType.benefitAssignmentStatus]: BenefitAssignmentStatusCell,
  [CustomRendererType.keyLabelMap]: KeyLabelMap,
  [CustomRendererType.basicStatusPill]: BasicStatusPill,
};

export const useTableFilterTypes = () => {
  const { t } = useTranslation();
  const { currency } = useCurrency();

  const numberRangeFilter = useMemo(
    () => ({
      type: MpFilterType.numberRange,
      label: t('table.cost_range'),
      unitLabel: currency,
    }),
    [currency, t],
  );

  const dateRangeFilter = useMemo(
    () => ({
      type: 'dateRange' as any,
      format: 'L',
      label: t('table.date_range'),
    }),
    [t],
  );

  const countRangeFilter = useMemo(
    () => ({
      type: MpFilterType.numberRange,
      label: t('employees.employee_count'),
    }),
    [t],
  );

  const getSearchSelectFilter = useCallback(
    (
      columnId: string,
      items?: SearchSelectFilterItem[],
    ): SearchSelectFilterType => {
      return {
        type: MpFilterType.searchSelect,
        asyncGet: columnId,
        items,
      };
    },
    [],
  );

  const getFilterNameSearchSelectFilter = useCallback((columnId: string) => {
    return {
      type: 'filterNameSearchSelect' as any,
      asyncGet: columnId,
    };
  }, []);

  const getSelectFilter = useCallback(
    (columnId: string, items: SearchSelectFilterItem[]) => {
      return {
        type: 'selectionFilter' as any,
        asyncGet: columnId,
        items,
      };
    },
    [],
  );

  const getCurrencyRangeFilter = useCallback(
    (label?: string) => ({
      type: MpFilterType.numberRange,
      label,
      unitLabel: currency,
    }),
    [currency],
  );

  const getBasicActivityStatusFilter = useMemo(
    () =>
      getSelectFilter('status', [
        {
          label: t('status.inactive'),
          value: BasicActivityStatus.inactive,
        },
        {
          label: t('status.active'),
          value: BasicActivityStatus.active,
        },
      ]),
    [getSelectFilter, t],
  );

  return {
    countRangeFilter,
    numberRangeFilter,
    getSearchSelectFilter,
    dateRangeFilter,
    getFilterNameSearchSelectFilter,
    getSelectFilter,
    getCurrencyRangeFilter,
    getBasicActivityStatusFilter,
  };
};

const useTableQuery = (key?: HeaderVariant) => {
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState<string>('/');
  const currentUrl = useMemo(
    () => `${location?.pathname}${location?.search}`,
    [location?.pathname, location?.search],
  );
  const isSameLocation = useMemo(
    () => lastLocation === currentUrl,
    [lastLocation, currentUrl],
  );
  const {
    variables,
    setDatepickerVariables,
    setDatepickerValue,
    setVariables,
    setSearch,
    variant: headerVariant,
  } = useTableStore(
    ({
      variables,
      searchQuery,
      setSearch,
      setDatepickerVariables,
      setDatepickerValue,
      setVariables,
      variant,
    }) => {
      return {
        variables,
        searchQuery,
        setSearch,
        setDatepickerVariables,
        setDatepickerValue,
        setVariables,
        variant,
      };
    },
  );

  const breadcrumbQuery = useBreadcrumbQuery(key);
  const breadcrumbQueryParam = useMemo(
    () => (!!breadcrumbQuery ? `&${breadcrumbQuery}` : ''),
    [breadcrumbQuery],
  );
  const customColumnQuery = useCustomColumnQuery(key);
  const customColumnQueryParam = useMemo(
    () => (!!customColumnQuery ? `&${customColumnQuery}` : ''),
    [customColumnQuery],
  );

  const cleanTableState = useCallback(() => {
    if (!!key && !isSameLocation && key !== headerVariant) {
      setDatepickerVariables({ dateTo: '', dateFrom: '' });
      setDatepickerValue('');
      setVariables({
        columns: [],
        filters: [],
        sort: [],
        cursor: undefined,
        limit: undefined,
      });
      setSearch('');
    }
  }, [
    isSameLocation,
    key,
    setDatepickerValue,
    setDatepickerVariables,
    setSearch,
    setVariables,
    headerVariant,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cleanTableState(), [currentUrl]);

  useEffect(() => setLastLocation(currentUrl), [currentUrl]);

  const parsedSortItems = useMemo<string>(() => {
    const sortItems = variables.sort;
    if (!sortItems?.length) return '';

    return sortItems.reduce((prev, item) => {
      const andSymbol = !prev ? '' : '&';
      const descSymbol = item.desc ? '-' : '';
      return `${prev}${andSymbol}${descSymbol}${item.key}`;
    }, '');
  }, [variables.sort]);

  const sortQueryParam = useMemo<string>(
    () => (!!parsedSortItems ? `&sort=${parsedSortItems}` : ''),
    [parsedSortItems],
  );

  const parseFilterArray = useCallback((item: FilterItem): string => {
    const columnId = item.id as string;
    const filterItems = item.value as string[];
    return filterItems.reduce((prev: string, filterValue: string) => {
      return `${prev}${
        !prev ? '' : '&'
      }filter[${columnId}]=${encodeURIComponent(filterValue)}`;
    }, '');
  }, []);

  const parseFilterDate = useCallback((item: FilterItem): string => {
    const filterValues = item.value as DateFilterValue;
    const columnId = item.id as string;
    const fromDate = filterValues?.from;
    const toDate = filterValues?.to;
    const parsedFromQuery = !!fromDate
      ? `filter[${columnId}From]=${fromDate}`
      : `filter[${columnId}From]=${moment(0).startOf('day').toISOString()}`;
    const parsedToQuery = !!toDate
      ? `filter[${columnId}To]=${toDate}`
      : `filter[${columnId}To]=${moment().endOf('day').toISOString()}`;
    return `${parsedFromQuery}&${parsedToQuery}`;
  }, []);

  const parseFilterCount = useCallback((item: FilterItem): string => {
    const filterValues = item.value as CountFilterValue;
    const columnId = item.id as string;
    const maxCount = filterValues?.max;
    const minCount = filterValues?.min;
    const parsedMinCountQuery = !!minCount
      ? `filter[${columnId}From]=${minCount}`
      : `filter[${columnId}From]=0`;
    const parsedMaxCountQuery = !!maxCount
      ? `filter[${columnId}To]=${maxCount}`
      : `filter[${columnId}To]=999999999999`;
    return `${parsedMinCountQuery}&${parsedMaxCountQuery}`;
  }, []);

  const parseFilterInvestment = useCallback((item: FilterItem): string => {
    const filterValues = item.value as InvestmentFilterValue;
    const columnId = item.id as string;
    const maxInvestment = filterValues?.max;
    const minInvestment = filterValues?.min;
    const andSymbol = !!minInvestment ? '&' : '';
    const parsedMinInvestmentQuery = !!minInvestment
      ? `filter[${columnId}From]=${minInvestment}`
      : '';
    const parsedMaxInvestmentQuery = !!maxInvestment
      ? `filter[${columnId}To]=${maxInvestment}`
      : !!minInvestment
      ? `filter[${columnId}To]=999999999999`
      : '';
    return `${parsedMinInvestmentQuery}${andSymbol}${parsedMaxInvestmentQuery}`;
  }, []);

  const filterQueryParam = useMemo<string>(() => {
    const filterItems = variables.filters;
    if (!filterItems?.length) return '';

    return filterItems?.reduce((prev: string, item: FilterItem) => {
      const andSymbol = !prev ? '' : '&';
      if (isArray(item.value)) {
        const parsedArrayString = parseFilterArray(item);
        return `${prev}${andSymbol}${parsedArrayString}`;
      }

      if (
        !!(item?.value as DateFilterValue)?.from ||
        !!(item?.value as DateFilterValue)?.to
      ) {
        const parsedDateString = parseFilterDate(item);
        return `${prev}${andSymbol}${parsedDateString}`;
      }

      if (
        !!(item?.value as CountFilterValue)?.max ||
        !!(item?.value as CountFilterValue)?.min
      ) {
        const parsedCountString = parseFilterCount(item);
        return `${prev}${andSymbol}${parsedCountString}`;
      }

      if (
        !!(item?.value as InvestmentFilterValue)?.max ||
        !!(item?.value as InvestmentFilterValue)?.min
      ) {
        const parsedInvestmentString = parseFilterInvestment(item);
        return `${prev}${andSymbol}${parsedInvestmentString}`;
      }

      return prev;
    }, '');
  }, [
    parseFilterArray,
    parseFilterDate,
    parseFilterInvestment,
    parseFilterCount,
    variables.filters,
  ]);

  const datePickerQueryParam = useMemo<string>(() => {
    if (!isSameLocation || (!variables?.dateFrom && !variables?.dateTo))
      return '';
    if (variables?.dateFrom === 'all' && variables?.dateTo === 'all') return '';
    if (!!variables?.dateFrom && !!variables?.dateTo)
      return `&filter[dateFrom]=${variables.dateFrom}&filter[dateTo]=${variables.dateTo}`;

    return '';
  }, [isSameLocation, variables.dateFrom, variables.dateTo]);

  const pageQueryParam = useMemo(() => {
    const { cursor, limit } = variables;
    if (isDefined(cursor) && limit) {
      // Cursor starts from 0
      const page = Math.ceil((cursor + 1) / limit);
      // Ignore parameter page=1 as endpoints load first page by default
      return page > 1 ? `&page=${page}` : '';
    }
    return '';
  }, [variables]);

  const tableQueryParam = useMemo<string | undefined>(() => {
    const isDashboard = key === 'dashboard';

    // query is undefined if variables for current table is not yet updated
    if (key && !isDashboard && (!isSameLocation || !variables?.columns?.length))
      return undefined;

    const queryString = isSameLocation
      ? `${filterQueryParam}${sortQueryParam}${datePickerQueryParam}${breadcrumbQueryParam}${customColumnQueryParam}${pageQueryParam}`
      : `${breadcrumbQueryParam}${datePickerQueryParam}`;
    return queryString.charAt(0) === '&'
      ? queryString.substring(1)
      : queryString;
  }, [
    key,
    isSameLocation,
    variables?.columns?.length,
    filterQueryParam,
    sortQueryParam,
    datePickerQueryParam,
    breadcrumbQueryParam,
    customColumnQueryParam,
    pageQueryParam,
  ]);

  // useEffect(() => {
  //   history.push({
  //     pathname: location.pathname,
  //     search: !!location?.search
  //       ? `${location?.search}&${tableQueryParam}`
  //       : tableQueryParam,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tableQueryParam]);

  return { tableQueryParam, datePickerQueryParam };
};

const useCustomColumnQuery = (key?: HeaderVariant) => {
  const customColumn = useTableStore((state) => state.customColumn);

  const customColumnTables = useMemo(
    () => ['employees', 'benefitsAssignEmployees', 'innerGroupAssignEmployees'],
    [],
  );

  const hasCustomColumn = useMemo(
    () => !!key && customColumnTables?.includes(key),
    [key, customColumnTables],
  );

  const customColumnQueryParam = useMemo(
    () => (!!customColumn && hasCustomColumn ? `category=${customColumn}` : ''),
    [customColumn, hasCustomColumn],
  );

  return customColumnQueryParam;
};

const useBreadcrumbQuery = (key?: HeaderVariant) => {
  const { isMelpAdmin } = useMe();
  const { variables } = useTableStore(({ variables }) => {
    return { variables };
  });

  const breadcrumb = variables.globalFilters?.breadcrumb;

  const companyGroupQueryParam = useMemo(
    () =>
      !!breadcrumb?.company_groups?.id
        ? `companyGroupId=${breadcrumb?.company_groups?.id}`
        : '',
    [breadcrumb?.company_groups?.id],
  );

  const companyQueryParam = useMemo(
    () =>
      !!breadcrumb?.companies?.id
        ? `&companyId=${breadcrumb?.companies?.id}`
        : '',
    [breadcrumb?.companies?.id],
  );

  const departmentQueryParam = useMemo(
    () =>
      !!companyQueryParam && !!breadcrumb?.departments?.id
        ? `&departmentId=${breadcrumb?.departments?.id}`
        : '',
    [breadcrumb?.departments?.id, companyQueryParam],
  );

  const breadcrumbQueryParam = useMemo(() => {
    if (
      !breadcrumb ||
      Object.keys(breadcrumb)?.length < 1 ||
      noBreadcrumbs.includes(key ?? '') ||
      isMelpAdmin
    )
      return '';
    return `${companyGroupQueryParam}${companyQueryParam}${departmentQueryParam}`;
  }, [
    breadcrumb,
    companyGroupQueryParam,
    companyQueryParam,
    departmentQueryParam,
    key,
    isMelpAdmin,
  ]);

  return breadcrumbQueryParam;
};

const useTableUtils = (key?: HeaderVariant) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { tableQueryParam, datePickerQueryParam } = useTableQuery(key);
  const breadcrumbQueryParam = useBreadcrumbQuery();
  const customColumnQueryParam = useCustomColumnQuery(key);
  const setHeaderVariant = useTableStore((state) => state.setVariant);
  const setCustomColumn = useTableStore((state) => state.setCustomColumn);
  const setVariables = useTableStore((state) => state.setVariables);
  const resetAllChanges = useInvestmentCalculationsStore(
    (state) => state.resetAllChanges,
  );
  const resetStatusChanges = useInvestmentCalculationsStore(
    (state) => state.resetStatusChanges,
  );
  const resetInvestments = useInvestmentCalculationsStore(
    (state) => state.resetInvestments,
  );
  const resetRecipientChanges = useNewsRecipientsStore(
    (state) => state.resetChanges,
  );
  const { storeData } = useLocalStorage();

  useEffect(() => {
    if (key !== null && key !== undefined) {
      setHeaderVariant(key);
    }
    resetAllChanges();
    resetStatusChanges();
    resetInvestments();
    resetRecipientChanges();
  }, [
    key,
    setHeaderVariant,
    resetAllChanges,
    resetStatusChanges,
    resetInvestments,
    resetRecipientChanges,
  ]);

  const loadingComponent = useMemo(() => <Loader minHeight={100} />, []);

  const noDataComponent = useMemo(() => <NoData table />, []);

  const overridables = useMemo(() => {
    const obj: Partial<MpTableOverridablesMap> = {
      renderers: customRendererMap,
      filterFunctions: customFilterFunctionMap,
      filters: customFilterMap,
      headFilter: MpTableHeadFilter,
      headIndicator: HeadIndicator,
      globalActions: CustomGlobalActions,
      paginator: Paginator,
    };
    return obj;
  }, []);

  const isHeaderVariantWithStatus = useMemo(() => {
    const headerVariantWithStatusKeys = [
      'benefitsAssignEmployees',
      'benefitsAssignGroups',
      'innerEmployeeAssignBenefits',
      'innerGroupAssignBenefits',
    ];

    return headerVariantWithStatusKeys.includes(key ?? '');
  }, [key]);

  const isHeaderVariantWithImplementation = useMemo(() => {
    const headerVariantWithImplementatationKeys = [
      'innerBenefitsChoices',
      'choices',
      'innerEmployeeChoices',
      'innerGroupAssignEmployees',
    ];

    return headerVariantWithImplementatationKeys.includes(key ?? '');
  }, [key]);

  const tableClass = useMemo(() => {
    if (isHeaderVariantWithStatus) return classes.tableWithStatus;
    if (isHeaderVariantWithImplementation)
      return classes.tableWithImplementation;
    return classes.table;
  }, [
    classes.table,
    classes.tableWithImplementation,
    classes.tableWithStatus,
    isHeaderVariantWithImplementation,
    isHeaderVariantWithStatus,
  ]);

  const overrideClasses = useMemo(() => {
    const obj: MpTableClasses = {};
    obj.highlightedInactive = classes.inactiveRow;
    obj.table = tableClass;
    obj.tableWrapper = classes.tableWrapper;
    obj.row = 'MuiTableRow-hover';
    obj.hoveredCellWithFilters = classes.hoveredCellWithFilters;
    obj.highlightedError = classes.highlightedError;
    obj.highlightedWarning = classes.highlightedWarning;
    obj.highlightedSuccess = classes.highlightedSuccess;
    return obj;
  }, [
    classes.highlightedError,
    classes.highlightedSuccess,
    classes.highlightedWarning,
    classes.hoveredCellWithFilters,
    classes.inactiveRow,
    classes.tableWrapper,
    tableClass,
  ]);

  const assignEmployeesOverrideClasses = useMemo(
    () => ({ ...overrideClasses, row: classes.assignEmployeesRow }),
    [classes.assignEmployeesRow, overrideClasses],
  );

  const translations = useMemo(() => {
    const obj: MpTableTranslations = {
      clear: t('table.clear'),
      search: t('common.search'),
      all: t('common.all'),
      breadcrumbs: {
        company_groups: t('sidebar.companyGroups'),
        companies: t('sidebar.companies'),
        departments: t('companies.departments'),
        no_items: t('common.no_items'),
      },
      total: t('common.actions'),
      from: t('table.from'),
      to: t('table.to'),
    };
    return obj;
  }, [t]);

  const getRowInvestment = useCallback(
    (newStatus: string, prevStatus: string, investment: number) => {
      if (newStatus === prevStatus || !investment) return null;
      if (newStatus === 'off') return `- ${investment}`;
      if (newStatus === 'flex' || newStatus === 'on') return `+ ${investment}`;
      return null;
    },
    [],
  );

  const getCurrentStatusBoolean = useCallback(
    (newStatus: any, prevStatus: any) => {
      if (newStatus === prevStatus) return null;
      if (newStatus === 'flex' || newStatus === 'on' || newStatus === 'off')
        return false;
      return null;
    },
    [],
  );

  const isRowInvestmentAdded = useCallback(
    (initialStatus: AssignmentStatus, newStatus: AssignmentStatus) => {
      return initialStatus === 'off' && newStatus !== 'off';
    },
    [],
  );

  const getRowInvestmentSwitch = useCallback(
    (
      value: string | number | undefined | null,
      switchBoolean: boolean,
      investment: number | undefined,
    ) => {
      if (value !== null) {
        if (value) {
          if (switchBoolean) {
            return `-${investment}`;
          } else {
            return 0;
          }
        } else {
          if (!switchBoolean) {
            return `+${investment}`;
          } else {
            return 0;
          }
        }
      } else {
        return 0;
      }
    },
    [],
  );

  const handleGetData = useCallback(
    (variables: MpTableDataArguments) => setVariables(variables),
    [setVariables],
  );

  const updateCustomColumn = useCallback(
    (customColumn: string | null) => {
      setCustomColumn(customColumn);
      !!customColumn && storeData(customColumn, StorageKeys.customColumn);
    },
    [setCustomColumn, storeData],
  );

  return {
    overridables,
    overrideClasses,
    translations,
    getRowInvestment,
    getCurrentStatusBoolean,
    isRowInvestmentAdded,
    getRowInvestmentSwitch,
    handleGetData,
    assignEmployeesOverrideClasses,
    loadingComponent,
    noDataComponent,
    tableQueryParam,
    breadcrumbQueryParam,
    customColumnQueryParam,
    datePickerQueryParam,
    updateCustomColumn,
  };
};

export default useTableUtils;
