import { useCallback, useMemo } from 'react';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { HeaderVariant } from '../state/Table';
import {
  GlobalPermissionModules,
  LocalPermissionModules,
  PermissionOptions,
} from '../constants/Administrators';
import {
  FullHierarchyResponse,
  HierarchyRequest,
  HierarchyResponse,
  HierarchyResponseItem,
} from '../types/ParentCompany';
import { useMe } from '../state/Administrators';
import { Company } from '../types/Companies';
import { HierarchyLevels } from './../constants/ParentCompany';

export const useHierarchyUtils = (
  data: HierarchyResponse,
  level: HierarchyLevels,
) => {
  const { t } = useTranslation();

  const removeSupportedLanguages = useCallback(
    (data: HierarchyResponse): HierarchyResponse => {
      return data?.map((item: HierarchyResponseItem) => {
        const entries = Object.entries(item);
        const arrayEntry = entries.filter(
          ([key, value]) => isArray(value) && key !== 'supportedLanguages',
        )[0] ?? [undefined, undefined];
        const [key, value] = arrayEntry;
        if (key === undefined)
          return {
            ...item,
            supportedLanguages: undefined,
          };
        return {
          ...item,
          supportedLanguages: undefined,
          [key]: removeSupportedLanguages(value ?? []),
        };
      });
    },
    [],
  );

  const parsedUngroupedCompanies = useMemo(() => {
    if (!data || !data?.length) return [];
    return data?.map((item) => ({
      ...item,
      name: item?.name ?? t('companies.ungrouped'),
    }));
  }, [data, t]);

  const removeEmptyDepartments = useCallback((data: Company[]) => {
    const dataCopy = [...data];
    dataCopy?.forEach((company) => {
      if (!company?.departments?.length) delete company?.departments;
    });
    return dataCopy;
  }, []);

  const parseCompanyAndCompanyGroups = useCallback(
    (data: FullHierarchyResponse[]) => {
      if (!data?.length) return {};
      if (data?.length === 1 && data[0].id === 'ungrouped')
        return {
          companies: removeEmptyDepartments(data[0].companies),
        };
      else {
        const parsedCompanyGroups = data?.map((group) => ({
          ...group,
          companies: removeEmptyDepartments(group?.companies),
        }));
        return {
          company_groups: parsedCompanyGroups,
        };
      }
    },
    [removeEmptyDepartments],
  );

  const hierarchy = useMemo(() => {
    if (!data) return {};
    const parsedData = removeSupportedLanguages(parsedUngroupedCompanies);
    switch (level) {
      case HierarchyLevels.COMPANY_AND_COMPANY_GROUPS:
        return {
          company_groups: parsedData,
        };
      case HierarchyLevels.COMPANY_AND_DEPARTMENT:
        return {
          companies: parsedData,
        };
      default:
        return {
          company_groups: parsedData,
        };
    }
  }, [data, level, parsedUngroupedCompanies, removeSupportedLanguages]);

  const breadcrumbsHierarchy = useMemo(() => {
    if (!data) return {};
    const parsedData = removeSupportedLanguages(parsedUngroupedCompanies);
    switch (level) {
      case HierarchyLevels.COMPANY_AND_COMPANY_GROUPS:
        return parseCompanyAndCompanyGroups(
          parsedData as FullHierarchyResponse[],
        );
      case HierarchyLevels.COMPANY_AND_DEPARTMENT:
        return {
          companies: removeEmptyDepartments(parsedData),
        };
      default:
        return parseCompanyAndCompanyGroups(
          parsedData as FullHierarchyResponse[],
        );
    }
  }, [
    data,
    level,
    parseCompanyAndCompanyGroups,
    parsedUngroupedCompanies,
    removeEmptyDepartments,
    removeSupportedLanguages,
  ]);

  return {
    parsedUngroupedCompanies,
    hierarchy,
    breadcrumbsHierarchy,
  };
};

export const useHierarchyByHeaderVariant = () => {
  const { checkIfCanView, checkIfCanEdit } = useMe();
  const getPermissionType = useCallback(
    (module: LocalPermissionModules | GlobalPermissionModules | null) => {
      if (!module) return PermissionOptions.None;
      if (checkIfCanEdit(module)) return PermissionOptions.Edit;
      if (checkIfCanView(module)) return PermissionOptions.View;
      return PermissionOptions.None;
    },
    [checkIfCanEdit, checkIfCanView],
  );

  const getHierarchyRequestByHeaderVariant = useCallback(
    (variant: HeaderVariant): HierarchyRequest => {
      const hierarchyModulesByVariant: Record<
        HeaderVariant,
        GlobalPermissionModules | LocalPermissionModules | null
      > = {
        '': null,
        groups: GlobalPermissionModules.EmployeeGroups,
        innerEmployeeAssignGroups: LocalPermissionModules.Employees,
        innerEmployeeTermsAcceptance: null,
        termsAcceptance: null,
        dashboard: LocalPermissionModules.Dashboard,
        innerBenefitTermsAcceptance: null,
        common: null,
        companySettingsAdministrators: null,
        companySettingsCompanies: null,
        companySettingsCompanyGroups: null,
        employeeCategories: null,
        clientAdministrators: null,
        recipients: null,
      };

      const hierarchyModule = hierarchyModulesByVariant[variant];
      const hierarchyPermissions = getPermissionType(hierarchyModule);
      const hierarchyLevel = HierarchyLevels.FULL_HIERARCHY;

      return {
        level: hierarchyLevel,
        module: hierarchyModule,
        permissions: hierarchyPermissions,
      };
    },
    [getPermissionType],
  );

  return {
    getHierarchyRequestByHeaderVariant,
  };
};
