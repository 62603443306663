import { TypographyColor } from 'melp-design/components';
import { EmployeeStatus } from './employees.types';

export const COLOR_BY_STATUS: Record<EmployeeStatus, TypographyColor> = {
  active: 'success',
  ended: 'textSecondary',
  endsSoon: 'warning',
  inactive: 'textSecondary',
  recentlyEnded: 'warning',
};
