import { IRecognitionAchievement } from 'types/income';
import { convertUploadedFile } from 'store/files';
import { loaders } from './recognition-achievements.loaders';
import { RecognitionAchievement } from './recognition-achievements.types';

const convertAchievement = (
  achievement: IRecognitionAchievement,
): RecognitionAchievement => {
  return {
    id: achievement.id,
    name: achievement.name,
    points: achievement.points,
    limit: achievement.limit,
    limitPeriod: achievement.limitPeriod,
    image: achievement.image ? convertUploadedFile(achievement.image) : null,
    requiresApproval: achievement.requiresApproval,
    priorityIndex: achievement.priorityIndex,
    employeeGroups: achievement.employeeGroups ?? [],
    translations: achievement.translations,
  };
};

const convertAchievements = (
  achievements: IRecognitionAchievement[],
): RecognitionAchievement[] => {
  return achievements.map(convertAchievement);
};

export const converters = {
  getAchievements: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getAchievements>>) => {
    return convertAchievements(data);
  },
};
