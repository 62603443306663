import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { Translations } from 'types/Common';
import { FrequencyUnit } from 'types/general';
import { IRecognitionAchievement } from 'types/income';

interface UpdateAchievement {
  achievementId: string;
  data: Partial<{
    name: string;
    points: number;
    requiresApproval: boolean;
    limit: number;
    limitPeriod: FrequencyUnit;
    imageId: string;
    translations: Translations<{ title: string; description: string }>;
    employeeGroups: string[];
  }>;
}

interface RemoveAchievement {
  achievementId: string;
}

interface UpdateAchievementsPriorities {
  priorities: Array<{ id: string; index: number }>;
}

export const loaders = {
  getAchievements: () => {
    return api<IRecognitionAchievement[]>({
      url: Endpoints.clientAdmin.recognitions.achievements.root,
    });
  },
  updateAchievement: ({ achievementId, data }: UpdateAchievement) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.recognitions.achievements.byId.replace(
        ':achievementId',
        achievementId,
      ),
      data: { ...data },
      method: 'patch',
    });
  },
  removeAchievement: ({ achievementId }: RemoveAchievement) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.recognitions.achievements.byId.replace(
        ':achievementId',
        achievementId,
      ),
      method: 'delete',
    });
  },
  updateAchievementsPriorities: ({
    priorities,
  }: UpdateAchievementsPriorities) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.recognitions.achievements.priorities,
      data: {
        achievements: priorities.map((priority) => ({
          id: priority.id,
          priorityIndex: priority.index,
        })),
      },
      method: 'patch',
    });
  },
};
