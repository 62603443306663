import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { recognitionTypes } from 'store/recognitions';

export const recognitionLeaderboardFiltersSchema = z
  .object({
    search: z.string().catch(''),
    type: z.enum(recognitionTypes).nullable().catch(null),
    employees: z.array(z.string()).catch([]),
    companies: z.array(z.string()).catch([]),
    departments: z.array(z.string()).catch([]),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema)
  .merge(sortFiltersSchema);
