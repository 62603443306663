import { z } from 'zod';
import { employeeGroupsFiltersSchema } from './employee-groups.schemas';

export const employeeInGroupStatuses = ['included', 'excluded'] as const;
export type EmployeeInGroupStatus = (typeof employeeInGroupStatuses)[number];

export interface EmployeesGroup {
  id: string;
  name: string;
}

export type EmployeeGroupsFilters = z.infer<typeof employeeGroupsFiltersSchema>;
