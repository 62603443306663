import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { noBreadcrumbs } from '../../../constants/ParentCompany';
import { useMe } from '../../../state/Administrators';
import { HeaderVariant, useTableStore } from '../../../state/Table';

interface IProps {
  children?: React.ReactNode;
}

export default function HeaderVariantMap({ children }: IProps) {
  const headerVariant =
    useTableStore((state) => state.variant as HeaderVariant) ?? 'benefits';
  const { isMelpAdmin } = useMe();

  const header = useMemo(
    () => ({
      '': null,
      groups: null,
      innerEmployeeAssignGroups: null,
      innerEmployeeTermsAcceptance: null,
      termsAcceptance: null,
      dashboard: null,
      innerBenefitTermsAcceptance: null,
      common: null,
      companySettingsAdministrators: null,
      companySettingsCompanies: null,
      companySettingsCompanyGroups: null,
      employeeCategories: null,
      clientAdministrators: null,
      recipients: null,
    }),
    [],
  );

  const Breadcrumbs = useMemo(() => {
    if (noBreadcrumbs.includes(headerVariant) || isMelpAdmin) return null;
    return <Box width={1}>{children}</Box>;
  }, [children, headerVariant, isMelpAdmin]);

  const headerVariantWidth = useMemo(
    () => (!!Breadcrumbs ? 'unset' : 1),
    [Breadcrumbs],
  );

  const spacing = useMemo(() => (!!Breadcrumbs ? 10 : 0), [Breadcrumbs]);

  if (!headerVariant) return null;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      {Breadcrumbs}
      <Box
        display="flex"
        alignItems="center"
        width={headerVariantWidth}
        ml={spacing}
      >
        {header[headerVariant as HeaderVariant]}
      </Box>
    </Box>
  );
}
