import { Img } from 'melp-design/icons';
import { Colors, Effects } from 'melp-design/style';
import { Paper } from '@mui/material';
import { FC } from 'react';

interface Props {
  image: string;
}

export const AchievementImage: FC<Props> = ({ image }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        width: 42,
        height: 42,
        boxShadow: Effects.smallElementsShadow,
        color: Colors.grey,
        display: 'grid',
        placeItems: 'center',

        img: {
          width: 40,
          height: 40,
          objectFit: 'cover',
          borderRadius: '7px',
        },
      }}
    >
      {image ? <img src={image} alt={image} /> : <Img />}
    </Paper>
  );
};
