import { useMutation } from '@tanstack/react-query';
import { converters as filesConverters } from 'store/files';
import { loaders } from './admin-reports.loaders';

export const useExportExpiredBenefits = () => {
  return useMutation(loaders.exportExpiredBenefits, {
    onSuccess: filesConverters.export,
  });
};

export const useExportSearchAttempts = () => {
  return useMutation(loaders.exportSearchAttempts, {
    onSuccess: filesConverters.export,
  });
};

export const useExportShopItemsContent = () => {
  return useMutation(loaders.exportShopItemsContent, {
    onSuccess: filesConverters.export,
  });
};

export const useExportShopItemsPriceSettings = () => {
  return useMutation(loaders.exportShopItemsPriceSettings, {
    onSuccess: filesConverters.export,
  });
};

export const useExportDiscountsUsage = () => {
  return useMutation(loaders.exportDiscountsUsage, {
    onSuccess: filesConverters.export,
  });
};
