import { FC, useState } from 'react';
import {
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Divider, Stack, Tooltip } from '@mui/material';
import { APP, ROUTES } from 'config';
import {
  COLOR_BY_STATUS,
  SearchFilterName,
  adminOrdersFiltersSchema,
  useAdminOrders,
  useBulkUpdate,
  useExportToXls,
  useFilterValues,
} from 'store/admin-orders';
import { orderStatuses, OrderStatus } from 'store/orders';
import { calculateFiltersTotal } from 'utils/Filters';
import {
  AdminOrdersFilter,
  DateRangeFilter,
  FilterButton,
  Filters,
  SelectFilter,
} from 'components/filters';
import moment from 'moment';
import { formatCurrency, formatList } from 'utils/format';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { useConfirm } from 'store/confirm';
import { SystemColors } from 'melp-design/style';
import { useSearchParams } from 'utils/navigation';

export const AdminOrdersList: FC = () => {
  const { t, i18n } = useTranslation();
  const { confirmation } = useConfirm();

  const [filtersSearch, setFiltersSearch] = useState<
    Record<SearchFilterName, string>
  >({
    companyName: '',
    parentCompanyName: '',
    itemName: '',
    itemSku: '',
  });
  const { selectedRowIds, setSelectedRowIds } = Table.useSelectedRows();

  const { searchParams: filters, navigate } = useSearchParams(
    adminOrdersFiltersSchema,
  );

  const { data: orders, isLoading: isOrdersLoading } = useAdminOrders(filters);
  const {
    data: filterValuesParentCompanies,
    isInitialLoading: isFilterValuesParentCompaniesLoading,
  } = useFilterValues('parentCompanyName', filtersSearch.parentCompanyName);
  const {
    data: filterValuesCompanies,
    isInitialLoading: isFilterValuesCompaniesLoading,
  } = useFilterValues('companyName', filtersSearch.companyName);
  const { data: filterValuesSku, isInitialLoading: isFilterValuesSkuLoading } =
    useFilterValues('itemSku', filtersSearch.itemSku);
  const {
    data: filterValuesItems,
    isInitialLoading: isFilterValuesItemsLoading,
  } = useFilterValues('itemName', filtersSearch.itemName);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();
  const { mutate: bulkUpdate, isLoading: isBulkUpdating } = useBulkUpdate();

  const onFiltersSearch = (filterName: SearchFilterName, search: string) => {
    setFiltersSearch((prev) => ({
      ...prev,
      [filterName]: search,
    }));
  };

  const getHint = (status: OrderStatus, createdAt: string) => {
    const SLA_WARNING = 18; // hours
    const SLA = 24; // hours

    const differenceInHours = moment().diff(createdAt, 'hours', true);
    const withinSLA = differenceInHours <= SLA;
    const withinSLAWarning = withinSLA && differenceInHours >= SLA_WARNING;
    const withSLARules = ['pending', 'in_progress'].includes(status);

    let hint = '';
    if (withSLARules) {
      if (withinSLAWarning) {
        hint = t('marketplaceOrders.SLAWarning', {
          SLAWaringThreshold: SLA_WARNING,
        });
      }
      if (!withinSLA) {
        hint = t('marketplaceOrders.SLAMissed', { SLAThreshold: SLA });
      }
    }

    if (status === 'failed') {
      hint = t('marketplaceOrders.orderFailed');
    }

    return hint;
  };

  const onBulkUpdate = async (
    status: OrderStatus,
    validFromStatuses: OrderStatus[],
  ) => {
    const selectedOrdersStatuses =
      orders?.items
        .filter((order) => Object.keys(selectedRowIds).includes(order.id))
        .map((order) => order.status) ?? [];
    if (
      selectedOrdersStatuses.some(
        (selectedOrdersStatus) =>
          !validFromStatuses.includes(selectedOrdersStatus),
      )
    ) {
      toast.error(
        t('marketplaceOrders.statusTransitionError', {
          allowedStatuses: formatList(
            i18n.language,
            validFromStatuses.map((status) =>
              t(`marketplaceOrders.status-${status}`),
            ),
          ),
          selectedStatus: t(`marketplaceOrders.status-${status}`),
          interpolation: { escapeValue: false },
        }),
      );
      return;
    }

    const { confirmed } = await confirmation(
      t('marketplaceOrders.statusUpdateConfirmation', {
        statusName: t(`marketplaceOrders.status-${status}`),
      }),
    );

    if (confirmed) {
      bulkUpdate(
        { ids: Object.keys(selectedRowIds), status },
        {
          onSuccess: () => {
            toast.success(t('common.completedSuccessfully'));
            setSelectedRowIds({});
          },
        },
      );
    }
  };

  return (
    <>
      <ListPageHeader
        title={t('menu.orders')}
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={orders?.total} />
            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={adminOrdersFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                onChangeCallback={() => setSelectedRowIds({})}
                showClearButton={!!calculateFiltersTotal(filters)}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('marketplaceOrders.orderDate')}
                      value={value.createdAt}
                      initialValue={initialValues.createdAt}
                      onChange={(v) => setFilter('createdAt', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                          suggestions={['week', 'month', 'year']}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.status')}
                      value={value.status}
                      initialValue={initialValues.status}
                      onChange={(v) => setFilter('status', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="status"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={orderStatuses.map((status) => ({
                            key: status,
                            label: t(`marketplaceOrders.status-${status}`),
                          }))}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceOrders.orderId')}
                      value={value.orderIds}
                      initialValue={initialValues.orderIds}
                      onChange={(v) => setFilter('orderIds', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <AdminOrdersFilter
                          name="orderIds"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceOrders.client')}
                      value={value.parentCompanies}
                      initialValue={initialValues.parentCompanies}
                      onChange={(v) => setFilter('parentCompanies', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="parentCompanies"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={filterValuesParentCompanies?.map((o) => ({
                            key: o,
                            label: o,
                          }))}
                          loadingOptions={isFilterValuesParentCompaniesLoading}
                          asyncSearch
                          onSearch={(v) =>
                            onFiltersSearch('parentCompanyName', v)
                          }
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceOrders.company')}
                      value={value.companies}
                      initialValue={initialValues.companies}
                      onChange={(v) => setFilter('companies', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="companies"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={filterValuesCompanies?.map((o) => ({
                            key: o,
                            label: o,
                          }))}
                          loadingOptions={isFilterValuesCompaniesLoading}
                          asyncSearch
                          onSearch={(v) => onFiltersSearch('companyName', v)}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceItems.sku')}
                      value={value.sku}
                      initialValue={initialValues.sku}
                      onChange={(v) => setFilter('sku', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="sku"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={filterValuesSku?.map((o) => ({
                            key: o,
                            label: o,
                          }))}
                          loadingOptions={isFilterValuesSkuLoading}
                          asyncSearch
                          onSearch={(v) => onFiltersSearch('itemSku', v)}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('marketplaceItems.name')}
                      value={value.items}
                      initialValue={initialValues.items}
                      onChange={(v) => setFilter('items', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          name="items"
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                          options={filterValuesItems?.map((o) => ({
                            key: o,
                            label: o,
                          }))}
                          loadingOptions={isFilterValuesItemsLoading}
                          asyncSearch
                          onSearch={(v) => onFiltersSearch('itemName', v)}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </Filters>

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={orders?.total ?? 0}
                exportToExcel={() =>
                  exportToXls({
                    filters: {
                      ...filters,
                      orderIds: Object.keys(selectedRowIds),
                    },
                  })
                }
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        }
      />

      <Table
        data={orders?.items ?? []}
        isLoading={isOrdersLoading}
        stickyHeader
        rows={{
          color: ({ original: order }) => {
            if (
              ['pending', 'in_progress'].includes(order.status) &&
              moment().diff(order.createdAt, 'hours', true) <= 24
            ) {
              return SystemColors.success.base + '25';
            }
            if (
              ['pending', 'in_progress'].includes(order.status) &&
              moment().diff(order.createdAt, 'hours', true) > 24
            ) {
              return SystemColors.danger.base + '25';
            }
          },
        }}
        columns={[
          {
            id: 'creationDate',
            header: t('marketplaceOrders.orderDate'),
            cell: ({ row: { original: order } }) => {
              return moment(order.createdAt).format('l LT');
            },
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: order } }) => {
              return (
                <Tooltip title={getHint(order.status, order.createdAt)}>
                  <div>
                    <Typography color={COLOR_BY_STATUS[order.status]}>
                      {t(`marketplaceOrders.status-${order.status}`)}
                    </Typography>
                  </div>
                </Tooltip>
              );
            },
          },
          {
            id: 'orderNr',
            header: t('marketplaceOrders.orderId'),
            cell: ({ row: { original: order } }) => {
              return (
                <Link
                  label={order.orderNr}
                  to={ROUTES.admin.orders.details.replace(':id', order.id)}
                />
              );
            },
          },
          {
            id: 'parentCompany',
            header: t('marketplaceOrders.client'),
            cell: ({ row: { original: order } }) => {
              return order.company.parentCompany ? (
                <Link
                  label={order.company.parentCompany.name}
                  to={ROUTES.admin.clients.details.settings.replace(
                    ':id',
                    order.company.parentCompany.id,
                  )}
                />
              ) : null;
            },
          },
          {
            id: 'company',
            header: t('marketplaceOrders.company'),
            cell: ({ row: { original: order } }) => {
              return order.company.name;
            },
          },
          {
            id: 'itemId',
            header: t('marketplaceItems.sku'),
            cell: ({ row: { original: order } }) => {
              return (
                <Link
                  label={order.shopItem.sku}
                  to={ROUTES.admin.shopItems.details.settings.replace(
                    ':id',
                    order.shopItem.id,
                  )}
                />
              );
            },
          },
          {
            id: 'itemName',
            header: t('marketplaceItems.name'),
            cell: ({ row: { original: order } }) => {
              return (
                <Link
                  label={order.shopItem.name}
                  to={ROUTES.admin.shopItems.details.settings.replace(
                    ':id',
                    order.shopItem.id,
                  )}
                />
              );
            },
          },
          {
            id: 'orderPrice',
            header: t('marketplaceOrders.marketplaceItemPrice'),
            cell: ({ row: { original: order } }) => {
              return formatCurrency(i18n.language, order.price, order.currency);
            },
            meta: { align: 'right' },
          },
          {
            id: 'lastModifiedBy',
            header: t('marketplaceOrders.lastModifiedBy'),
            cell: ({ row: { original: order } }) => {
              return order.isAutomatedVoucher ? (
                t('orders.modified_by.automated_voucher')
              ) : order.admin && order.company.parentCompany ? (
                <Link
                  label={order.admin.fullName}
                  to={ROUTES.admin.clients.details.administrators.details
                    .replace(':clientId', order.company.parentCompany.id)
                    .replace(':adminId', order.admin.id)}
                />
              ) : null;
            },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          orders && {
            count: orders.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
        selection={{
          value: selectedRowIds,
          onChange: setSelectedRowIds,
          actions: [
            {
              label: t('marketplaceOrders.markAsActionLabel', {
                statusName: 'invoiced',
              }),
              onClick: () => {
                onBulkUpdate('invoiced', ['done', 'paid']);
              },
            },
            {
              label: t('marketplaceOrders.markAsActionLabel', {
                statusName: 'paid',
              }),
              onClick: () => {
                onBulkUpdate('paid', ['done', 'invoiced']);
              },
            },
          ],
          isLoading: isBulkUpdating,
        }}
      />
    </>
  );
};
