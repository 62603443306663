import { FC, PropsWithChildren } from 'react';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'config';
import { RecognitionsDisabledFeatureAlert } from 'containers/recognitions/atoms';

export const RecognitionsLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <Stack gap={3}>
      <RecognitionsDisabledFeatureAlert />

      <Stack>
        <DetailsPageHeader title={t('recognition.recognitionSettings')} />

        <DetailsPageContent>
          <Stack gap={2}>
            <SideMenu
              tabs={[
                {
                  key: 'recognitions',
                  label: t('recognition.recognitionFeed'),
                  url: ROUTES.recognitions.list,
                  isActive: path === ROUTES.recognitions.list,
                },
                {
                  key: 'recipients',
                  label: t('recognition.recipientsTab'),
                  url: ROUTES.recognitions.recipients.list,
                  isActive: path === ROUTES.recognitions.recipients.list,
                },
                {
                  key: 'givers',
                  label: t('recognition.giversTab'),
                  url: ROUTES.recognitions.givers.list,
                  isActive: path === ROUTES.recognitions.givers.list,
                },
                {
                  key: 'leaderboard',
                  label: t('recognition.leaderboard.title'),
                  url: ROUTES.recognitions.leaderboard.list,
                  isActive: path === ROUTES.recognitions.leaderboard.list,
                },
                {
                  key: 'allocations',
                  label: t('recognition.allowancesOverviewTab'),
                  url: ROUTES.recognitions.allocations.list,
                  isActive: path === ROUTES.recognitions.allocations.list,
                },
              ]}
            />
            <SideMenu
              tabs={[
                {
                  key: 'settings',
                  label: t('common.settings'),
                  url: ROUTES.recognitions.settings,
                  isActive: path === ROUTES.recognitions.settings,
                },
                {
                  key: 'achievements',
                  label: t('recognition.achievementsTab'),
                  url: ROUTES.recognitions.achievements.list,
                  isActive: path === ROUTES.recognitions.achievements.list,
                },
                {
                  key: 'rules',
                  label: t('recognition.allowanceRulesTab'),
                  url: ROUTES.recognitions.rules.list,
                  isActive: path === ROUTES.recognitions.rules.list,
                },
                {
                  key: 'rewards',
                  label: t('recognition.rewardsTab'),
                  url: ROUTES.recognitions.rewards.list,
                  isActive: path === ROUTES.recognitions.rewards.list,
                },
              ]}
            />
          </Stack>
          {children}
        </DetailsPageContent>
      </Stack>
    </Stack>
  );
};
