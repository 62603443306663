import { FC } from 'react';
import { RecognitionsLayout } from 'pages/atoms';
import { RecognitionsLeaderboardList } from 'containers/recognitions';

const RecognitionsLeaderboardPage: FC = () => {
  return (
    <RecognitionsLayout>
      <RecognitionsLeaderboardList />
    </RecognitionsLayout>
  );
};

export default RecognitionsLeaderboardPage;
