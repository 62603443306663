import { Box, Stack } from '@mui/material';
import {
  Alert,
  Button,
  DataLossPrompt,
  FileInput,
  FileLink,
  Footer,
  LabeledValue,
  Link,
  Loader,
  NothingFoundAlert,
  NumberInput,
  Panel,
  SelectInput,
  TextField,
  Typography,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  formatCountry,
  formatCurrency,
  formatLanguage,
  formatList,
  formatPercents,
} from 'utils/format';
import { OrderStatus, orderStatuses } from 'store/orders';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useUploadFile } from 'store/files';
import { useAdminOrder, useUpdateAdminOrder } from 'store/admin-orders';
import { usePredefinedToasts } from 'utils/Toast';
import { useConfirm } from 'store/confirm';
import { useIdParam } from 'utils/useIdParam';
import { DeleteOutline } from '@mui/icons-material';
import { useSearchParams } from 'utils/navigation';
import {
  adminShopVouchersFiltersSchema,
  RedeemMethods,
} from 'store/admin-shop-vouchers';
import { ROUTES } from 'config';
import { fromCents, toCents } from 'utils/general';

interface Values extends RedeemMethods {
  couponFile: string;
  commentForEmployee: string;
  status: OrderStatus;
  commentForAdmin: string;
  purchasePrice: number;
}

const INITIAL_VALUES: Values = {
  couponFile: '',
  code: '',
  commentForEmployee: '',
  status: 'in_progress',
  commentForAdmin: '',
  purchasePrice: NaN,
  barCode: '',
  pin: '',
  qrCode: '',
  serial: '',
  url: '',
};

export const AdminOrderDetails: FC = () => {
  const { t, i18n } = useTranslation();
  const orderId = useIdParam();
  const predefinedToasts = usePredefinedToasts();
  const { confirmation } = useConfirm();
  const { stringifyParams } = useSearchParams(adminShopVouchersFiltersSchema);

  const { data: order, isLoading } = useAdminOrder(orderId);

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile();
  const { mutate: updateAdminOrder, isLoading: isUpdating } =
    useUpdateAdminOrder();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (order) {
      reset({
        couponFile: order.couponFileId,
        code: order.redeemMethods.code,
        barCode: order.redeemMethods.barCode,
        qrCode: order.redeemMethods.qrCode,
        pin: order.redeemMethods.pin,
        serial: order.redeemMethods.serial,
        url: order.redeemMethods.url,
        commentForEmployee: order.commentForEmployee,
        commentForAdmin: order.commentForAdmin,
        purchasePrice: fromCents(
          order.purchasePrice ?? order.voucher?.purchasePrice ?? NaN,
        ),
        status: order.status,
      });
    }
  }, [order, reset]);

  const [status, couponFile, code] = watch(['status', 'couponFile', 'code']);
  const isCouponRequired = ['done', 'invoiced', 'paid'].includes(status);
  const isCouponCodeRequired = isCouponRequired && !code;
  const isCouponFileRequired = isCouponRequired && !couponFile;

  const onSubmit: SubmitHandler<Values> = async (values) => {
    if (status === 'rejected') {
      const { confirmed } = await confirmation(
        t('marketplaceOrders.rejectionWarning'),
      );

      if (!confirmed) return;
    }

    updateAdminOrder(
      {
        orderId,
        data: {
          status: values.status,
          code: values.code,
          barCode: values.barCode,
          qrCode: values.qrCode,
          serial: values.serial,
          pin: values.pin,
          url: values.url,
          couponFile: values.couponFile,
          commentForEmployee: values.commentForEmployee,
          commentForAdmin: values.commentForAdmin,
          purchasePrice: toCents(values.purchasePrice),
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  if (isLoading) return <Loader />;
  if (!order) return <NothingFoundAlert />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          {order.status === 'failed' && (
            <Alert severity="error">{t('marketplaceOrders.orderFailed')}</Alert>
          )}

          <Stack gap={2} flexDirection="row">
            <Box width="50%">
              <Stack spacing={2}>
                <Panel title={t('marketplaceOrders.orderDetails')}>
                  <Stack spacing={2}>
                    <LabeledValue
                      label={t('marketplaceOrders.orderId')}
                      value={order.orderNr}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.orderDate')}
                      value={moment(order.createdAt).format('l LT')}
                    />
                  </Stack>
                </Panel>

                <Panel title={t('marketplaceOrders.itemDetails')}>
                  <Stack spacing={2}>
                    <LabeledValue
                      label={t('marketplaceOrders.itemID')}
                      value={order.shopItem.sku}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.itemCountries')}
                      value={formatList(
                        i18n.language,
                        order.shopItem.countries.map((country) =>
                          formatCountry(i18n.language, country),
                        ),
                      )}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.itemName')}
                      value={order.shopItem.name}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.marketplaceItemPrice')}
                      value={formatCurrency(
                        i18n.language,
                        order.shopItem.price,
                        order.currency,
                      )}
                    />
                    <LabeledValue
                      label={t(
                        'marketplaceOrders.marketplaceItemPriceDescription',
                      )}
                      value={order.shopItem.priceDescription}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.marketplaceItemVatRate')}
                      value={formatPercents(
                        i18n.language,
                        order.shopItem.vatRate,
                      )}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.marketplaceItemVatAmount')}
                      value={formatCurrency(
                        i18n.language,
                        order.shopItem.vatAmount,
                        order.currency,
                      )}
                    />
                    <LabeledValue
                      label={t(
                        'marketplaceOrders.marketplaceItemPriceWithoutVat',
                      )}
                      value={formatCurrency(
                        i18n.language,
                        order.shopItem.pricePreVat,
                        order.currency,
                      )}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.supplierComment')}
                      value={order.shopItem.additionalInfo}
                    />

                    <Controller
                      name="purchasePrice"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => (
                        <NumberInput
                          label={t('shop.vouchers.purchasePrice.label')}
                          name={name}
                          value={value}
                          onChange={onChange}
                          key={value}
                          required={status === 'done'}
                          InputProps={{
                            endAdornment: 'EUR',
                          }}
                          ref={ref}
                        />
                      )}
                    />

                    {order.voucher ? (
                      <>
                        <LabeledValue
                          label={t('shop.vouchers.purchaseReference.label')}
                          value={order.voucher?.purchaseReference}
                        />
                        <LabeledValue
                          label={t('shop.vouchers.id.label')}
                          value={
                            <Link
                              label={order.voucher.sequence}
                              to={`${ROUTES.admin.shopItems.details.vouchers.replace(
                                ':id',
                                order.shopItem.id,
                              )}${stringifyParams({
                                params: { search: order.voucher.id },
                              })}`}
                            />
                          }
                        />
                      </>
                    ) : null}
                  </Stack>
                </Panel>

                <Panel title={t('marketplaceOrders.clientDetails')}>
                  <Stack spacing={2}>
                    <LabeledValue
                      label={t('marketplaceOrders.client')}
                      value={order.company.parentCompany?.name}
                    />
                  </Stack>
                </Panel>

                <Panel title={t('marketplaceOrders.companyDetails')}>
                  <Stack spacing={2}>
                    <LabeledValue
                      label={t('marketplaceOrders.companyName')}
                      value={order.company.name}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.companyCode')}
                      value={order.company.code}
                    />
                    {order.company.contacts.map((contact, i) => (
                      <Stack spacing={1} py={2} key={i}>
                        <LabeledValue
                          label={t('marketplaceOrders.contactPerson')}
                          value={contact.fullName}
                        />
                        <LabeledValue
                          label={t('marketplaceOrders.contactEmail')}
                          value={contact.email}
                        />
                        <LabeledValue
                          label={t('marketplaceOrders.contactPhone')}
                          value={contact.phone}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Panel>

                <Panel title={t('marketplaceOrders.employeeDetails')}>
                  <Stack spacing={2}>
                    <LabeledValue
                      label={t('marketplaceOrders.employeeID')}
                      value={order.employee.id}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.employeeLanguage')}
                      value={formatLanguage(
                        i18n.language,
                        order.employee.language,
                      )}
                    />
                    <LabeledValue
                      label={t('order.first_access.label')}
                      value={
                        order.accessHistory.length
                          ? moment(order.accessHistory.at(0)).format('l LT')
                          : '-'
                      }
                    />
                    <LabeledValue
                      label={t('order.last_access.label')}
                      value={
                        order.accessHistory.length
                          ? moment(order.accessHistory.at(-1)).format('l LT')
                          : '-'
                      }
                    />
                  </Stack>
                </Panel>
              </Stack>
            </Box>

            <Box width="50%">
              <Stack spacing={2}>
                <Panel title={t('marketplaceOrders.publicInfo')}>
                  <Stack spacing={2}>
                    <Controller
                      name="couponFile"
                      control={control}
                      render={({ field: { name, onChange, ref } }) => {
                        return (
                          <Stack gap={0.75}>
                            <Typography variant="p1" color="textSecondary">
                              {t('marketplaceOrders.couponFile')}
                            </Typography>
                            {couponFile ? (
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                gap={1}
                              >
                                <FileLink id={couponFile} />
                                <Button
                                  variant="neutral-outline"
                                  icon={DeleteOutline}
                                  onClick={() => onChange('')}
                                />
                              </Stack>
                            ) : (
                              <FileInput
                                name={name}
                                onChange={async ([file]) => {
                                  if (file) {
                                    uploadFile(
                                      { file },
                                      {
                                        onSuccess: ({ data }) => {
                                          onChange(data.id);
                                        },
                                      },
                                    );
                                  } else {
                                    onChange('');
                                  }
                                }}
                                disabled={isUploading}
                                maxFiles={1}
                                previewSelectedFiles={false}
                                ref={ref}
                              />
                            )}
                          </Stack>
                        );
                      }}
                    />
                    <Controller
                      name="code"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('marketplaceOrders.couponCode')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            required={isCouponFileRequired}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="qrCode"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('shop.vouchers.redeem.qrCode')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="barCode"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('shop.vouchers.redeem.barCode')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="serial"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('shop.vouchers.redeem.serial')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="pin"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('shop.vouchers.redeem.pin')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="url"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('shop.vouchers.redeem.url')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="commentForEmployee"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('marketplaceOrders.commentForEmployee')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            multiline
                            ref={ref}
                          />
                        );
                      }}
                    />
                  </Stack>
                </Panel>

                <Panel title={t('marketplaceOrders.internalInfo')}>
                  <Stack spacing={2}>
                    {isCouponCodeRequired && isCouponFileRequired ? (
                      <Alert severity="warning">
                        {t('marketplaceOrders.couponInfoWarning')}
                      </Alert>
                    ) : null}

                    <Controller
                      name="status"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <SelectInput
                            label={t('common.status')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            disabled={order.status === 'rejected'}
                            options={orderStatuses.map((status) => ({
                              value: status,
                              label: t(`marketplaceOrders.status-${status}`),
                              disabled: status === 'failed',
                            }))}
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="commentForAdmin"
                      control={control}
                      render={({ field: { name, value, onChange, ref } }) => {
                        return (
                          <TextField
                            label={t('marketplaceOrders.commentForAdmin')}
                            name={name}
                            value={value}
                            onChange={onChange}
                            multiline
                            ref={ref}
                          />
                        );
                      }}
                    />
                    <LabeledValue
                      label={t('marketplaceOrders.managedBy')}
                      value={
                        order.isAutomatedVoucher
                          ? t('orders.modified_by.automated_voucher')
                          : order.admin?.fullName
                      }
                    />
                  </Stack>
                </Panel>
              </Stack>
            </Box>
          </Stack>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty} />
    </>
  );
};
