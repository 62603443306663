import { converters as tableConverters } from 'store/table';
import { IEmployeesGroup } from 'types/income';
import { loaders } from './employee-groups.loaders';
import { EmployeesGroup } from './employee-groups.types';

const convertEmployeesGroup = (
  employeesGroup: IEmployeesGroup,
): EmployeesGroup => {
  return {
    id: employeesGroup.id,
    name: employeesGroup.name,
  };
};

const convertEmployeesGroups = (
  employeesGroups: IEmployeesGroup[],
): EmployeesGroup[] => {
  return employeesGroups.map(convertEmployeesGroup);
};

export const converters = {
  getEmployeesGroups: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getEmployeesGroups>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertEmployeesGroups(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
