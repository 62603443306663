import { AxiosResponse } from 'axios';
import fileDownloader from 'js-file-download';
import { v4 as uuid } from 'uuid';
import { IFile } from 'types/income';
import { loaders } from './files.loaders';
import { UploadedFile } from './files.types';

const parse = (disposition: string) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  const filename = matches?.[1] ? matches[1].replace(/['"]/g, '') : uuid();

  return { filename };
};

export const convertUploadedFile = (file: IFile): UploadedFile => {
  return {
    id: file.id,
    url: file.signedUrl,
    mime: file.mime,
    name: file.fileName,
  };
};

const convertUploadedFiles = (files: IFile[]): UploadedFile[] => {
  return files.map(convertUploadedFile);
};

export const converters = {
  export: (response: AxiosResponse) => {
    const disposition = response.headers['content-disposition'];
    const { filename } = parse(disposition);

    return fileDownloader(response.data, filename);
  },
  getFile: ({ data }: Awaited<ReturnType<typeof loaders.getFile>>) => {
    return convertUploadedFile(data);
  },
  getFiles: ({ data }: Awaited<ReturnType<typeof loaders.getFiles>>) => {
    return convertUploadedFiles(data);
  },
  getUploadedFile: (file: IFile) => {
    return convertUploadedFile(file);
  },
  getUploadedFiles: (files: IFile[]) => {
    return convertUploadedFiles(files);
  },
};
