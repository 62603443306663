import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ListPageHeader, Typography } from 'melp-design/components';
import { Divider, Stack } from '@mui/material';
import {
  adminReportsFiltersSchema,
  useExportDiscountsUsage,
  useExportExpiredBenefits,
  useExportSearchAttempts,
  useExportShopItemsContent,
  useExportShopItemsPriceSettings,
} from 'store/admin-reports';
import { useSearchParams } from 'utils/navigation';
import { DateRangeFilter, FilterButton, Filters } from 'components/filters';
import { calculateFiltersTotal } from 'utils/Filters';

export const AdminReports: FC = () => {
  const { t } = useTranslation();
  const { searchParams: filters, navigate } = useSearchParams(
    adminReportsFiltersSchema,
  );

  const {
    mutate: exportExpiredBenefits,
    isLoading: isExportingExpiredBenefits,
  } = useExportExpiredBenefits();
  const { mutate: exportSearchAttempts, isLoading: isExportingSearchAttempts } =
    useExportSearchAttempts();
  const {
    mutate: exportShopItemsContent,
    isLoading: isExportingShopItemsContent,
  } = useExportShopItemsContent();
  const {
    mutate: exportShopItemsPriceSettings,
    isLoading: isExportingShopItemsPriceSettings,
  } = useExportShopItemsPriceSettings();
  const { mutate: exportDiscountsUsage, isLoading: isExportingDiscountsUsage } =
    useExportDiscountsUsage();

  return (
    <ListPageHeader
      title={t('alternatives.reports.title')}
      toolbarContent={
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap="10px">
            <Filters
              value={filters}
              initialValues={adminReportsFiltersSchema.parse({})}
              onChange={(v) => navigate({ params: v })}
              showClearButton={!!calculateFiltersTotal(filters)}
            >
              {({ value, initialValues, setFilter }) => (
                <FilterButton
                  label={t('common.date')}
                  value={value.date}
                  initialValue={initialValues.date}
                  onChange={(v) => setFilter('date', v)}
                >
                  {({ value, applyFilter, clearFilter, close }) => (
                    <DateRangeFilter
                      value={value}
                      onApply={applyFilter}
                      onClear={clearFilter}
                      onCancel={close}
                      suggestions={['week', 'month', 'quarter', 'year']}
                    />
                  )}
                </FilterButton>
              )}
            </Filters>
          </Stack>

          <Divider />

          <Stack gap={2}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h3">
                {t('reports.expired_benefits.label')}
              </Typography>
              <Button
                label={t('global.actions.export')}
                onClick={() => exportExpiredBenefits({ filters })}
                disabled={isExportingExpiredBenefits}
              />
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h3">
                {t('reports.search_attempts.label')}
              </Typography>
              <Button
                label={t('global.actions.export')}
                onClick={() => exportSearchAttempts({ filters })}
                disabled={isExportingSearchAttempts}
              />
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h3">{t('menu.items')}</Typography>
              <Button
                label={t('global.actions.export')}
                onClick={() => exportShopItemsContent()}
                disabled={isExportingShopItemsContent}
              />
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h3">
                {t('marketplaceItems.marketplaceItemPriceSettings')}
              </Typography>
              <Button
                label={t('global.actions.export')}
                onClick={() => exportShopItemsPriceSettings()}
                disabled={isExportingShopItemsPriceSettings}
              />
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="h3">
                {t('reports.discounts_usage.label')}
              </Typography>
              <Button
                label={t('global.actions.export')}
                onClick={() => exportDiscountsUsage({ filters })}
                disabled={isExportingDiscountsUsage}
              />
            </Stack>
          </Stack>
        </Stack>
      }
    />
  );
};
