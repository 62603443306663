import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './employee-groups.loaders';
import { converters } from './employee-groups.converters';
import { EmployeeGroupsFilters } from './employee-groups.types';

export const employeeGroupsKeys = queryKeyFactory('employee-groups');

export const useEmployeesGroups = (
  filters: Partial<EmployeeGroupsFilters> = {},
) => {
  const query = useQuery({
    queryKey: employeeGroupsKeys.list(filters),
    queryFn: () => loaders.getEmployeesGroups({ filters }),
    select: converters.getEmployeesGroups,
  });

  return query;
};
