import { FC, useEffect } from 'react';
import {
  Button,
  Footer,
  Loader,
  SortableList,
  TableToolbar,
  TableWrapper,
  Typography,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Add, Delete, DragIndicator, Edit } from '@mui/icons-material';
import { ROUTES } from 'config';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { SystemColors } from 'melp-design/style';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form-latest';
import { useConfirm } from 'store/confirm';
import { usePredefinedToasts } from 'utils/Toast';
import {
  useRecognitionAchievements,
  useRemoveRecognitionAchievement,
  useUpdateAchievementsPriorities,
} from 'store/recognition-achievements';
import { AchievementImage } from './atoms';

interface Values {
  achievements: Array<{ achievementId: string }>;
}

export const RecognitionsAchievementsLists: FC = () => {
  const history = useHistory();
  const predefinedToasts = usePredefinedToasts();
  const { t } = useTranslation();
  const { confirmation } = useConfirm();

  const pointsLabel = usePointsLabel();

  const { data: achievements, isLoading } = useRecognitionAchievements();

  const { mutate: removeAchievement, isLoading: isRemoving } =
    useRemoveRecognitionAchievement();
  const { mutate: updatePriorities, isLoading: isUpdatingPriorities } =
    useUpdateAchievementsPriorities();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: {
      achievements: [],
    },
  });
  const { fields: achiementsFields, move: moveAchievements } = useFieldArray({
    name: 'achievements',
    control,
  });

  useEffect(() => {
    if (achievements) {
      reset({
        achievements: achievements.map((achievement) => ({
          achievementId: achievement.id,
        })),
      });
    }
  }, [achievements, reset]);

  const onSubmit: SubmitHandler<Values> = (values) => {
    updatePriorities({
      priorities: values.achievements.map((achievement, i) => ({
        id: achievement.achievementId,
        index: i,
      })),
    });
  };

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TableWrapper>
        <TableToolbar>
          <Stack direction="row" alignItems="center" gap="15px" p="10px 5px">
            <Button
              icon={Add}
              variant="tertiary"
              onClick={() =>
                history.push(ROUTES.recognitions.achievements.create)
              }
            />
            <Typography variant="h2">
              {t('recognition.achievementsListTitle')}
            </Typography>
          </Stack>

          <SortableList
            key={JSON.stringify(
              achiementsFields.map((achievement) => achievement.achievementId),
            )}
            onPositionChange={moveAchievements}
            items={
              achiementsFields.map((field) => {
                const achievement = achievements?.find(
                  (a) => a.id === field.achievementId,
                );

                return {
                  id: field.achievementId,
                  content: achievement ? (
                    <Stack
                      sx={{ background: SystemColors.white, padding: 2 }}
                      flexDirection="row"
                      alignItems="center"
                      gap={3}
                      borderRadius={1}
                      boxShadow={` 0 0 0 1px ${SystemColors.grey[90]}`}
                      key={achievement.id}
                    >
                      <Box flexShrink={0} height={52}>
                        <DragIndicator
                          sx={{
                            height: '100%',
                            color: SystemColors.grey[55],
                          }}
                        />
                      </Box>

                      <AchievementImage image={achievement.image?.url ?? ''} />

                      <Stack flex={1} gap={0.25}>
                        <Typography variant="h3">{achievement.name}</Typography>
                        <Typography
                          color="textSecondary"
                          variant="h3"
                        >{`${achievement.points} ${pointsLabel}`}</Typography>
                        <Typography color="textSecondary" variant="p2">
                          {!achievement.limitPeriod || !achievement.limit
                            ? null
                            : `${achievement.limit} ${t(
                                `recognition.allowanceRuleFrequency-${achievement.limitPeriod}`,
                              )}`}
                        </Typography>
                      </Stack>

                      <Stack flexDirection="row" gap={1}>
                        <Button
                          icon={Edit}
                          label={t('common.edit')}
                          variant="neutral-outline"
                          size="sm"
                          disabled={isRemoving}
                          onClick={() =>
                            history.push(
                              ROUTES.recognitions.achievements.details.replace(
                                ':id',
                                achievement.id,
                              ),
                            )
                          }
                        />
                        <Button
                          icon={Delete}
                          label={t('actions.delete')}
                          variant="neutral-outline"
                          size="sm"
                          disabled={isRemoving}
                          onClick={async () => {
                            const { confirmed } = await confirmation(
                              t('common.areYouSureToDelete', {
                                title: achievement.name,
                              }),
                            );

                            if (confirmed) {
                              removeAchievement(
                                { achievementId: achievement.id },
                                {
                                  onSuccess: () => {
                                    predefinedToasts.success.deleted();
                                  },
                                },
                              );
                            }
                          }}
                        />
                      </Stack>
                    </Stack>
                  ) : null,
                };
              }) ?? []
            }
          />
        </TableToolbar>
      </TableWrapper>

      <Footer visible={isDirty}>
        <Stack direction="row" justifyContent="end" gap={1}>
          <Button
            label={t('common.cancel')}
            variant="neutral-outline"
            onClick={() => {
              reset(undefined, { keepDirty: false });
            }}
          />
          <Button
            label={t('common.save')}
            variant="primary"
            type="submit"
            disabled={isUpdatingPriorities}
          />
        </Stack>
      </Footer>
    </form>
  );
};
