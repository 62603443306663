export const ROUTES = {
  root: {
    index: '/',
    notFound: '/404',
  },
  public: {
    employeeTurnoverCalculator: '/employee-turnover-calculator',
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    newPassword: '/new-password',
    twoFactor: '/two-factor',
    changePassword: '/change-password',
    resetPassword: '/reset-password',
  },
  admin: {
    reports: '/admin/reports',
    logs: '/admin/logs',
    clients: {
      list: '/admin/clients',
      details: {
        settings: '/admin/clients/:id/settings',
        features: '/admin/clients/:id/features',
        deposits: {
          list: '/admin/clients/:id/deposits',
        },
        depositEvents: {
          list: '/admin/clients/:id/deposit-events',
        },
        balanceLogs: {
          details: '/admin/clients/:id/balance-logs/:companyId',
        },
        administrators: {
          list: '/admin/clients/:id/administrators',
          details: '/admin/clients/:clientId/administrators/:adminId',
        },
      },
    },
    orders: {
      list: '/admin/marketplace/orders',
      details: '/admin/marketplace/orders/:id',
    },
    discounts: {
      list: '/admin/discounts',
      details: {
        settings: '/admin/discounts/:id',
        codes: '/admin/discounts/:id/codes',
      },
      create: '/admin/discounts/create',
    },
    shopItems: {
      list: '/admin/marketplace/items',
      details: {
        settings: '/admin/marketplace/items/:id/settings',
        prices: '/admin/marketplace/items/:id/prices',
        content: '/admin/marketplace/items/:id/content',
        vouchers: '/admin/marketplace/items/:id/vouchers',
      },
    },
    suppliers: {
      list: '/admin/marketplace/suppliers',
      details: '/admin/marketplace/suppliers/:id',
    },
    templates: {
      list: '/admin/benefit-templates',
      details: '/admin/benefit-templates/:id',
    },
    integrations: {
      list: '/admin/integration-types',
      details: '/admin/integration-types/:id',
    },
  },
  analytics: '/analytics/dashboard',
  administrators: {
    details: '/administrators/:id',
  },
  shop: {
    external: {
      list: '/marketplace/deals',
    },
    internal: {
      items: {
        list: '/shop/internal/items',
        details: '/shop/internal/items/:id',
        create: '/shop/internal/items/create',
      },
      inventory: {
        list: '/shop/internal/inventory',
        details: '/shop/internal/inventory/:id',
      },
    },
  },
  discounts: {
    list: '/discounts',
  },
  orders: {
    list: '/marketplace/orders',
    internal: {
      details: '/orders/internal/:id',
    },
  },
  expenses: {
    list: '/expenses',
    external: {
      list: '/external-expenses',
    },
  },
  claims: {
    list: '/claims',
    details: '/claim-details/:id',
  },
  employees: {
    list: '/employees',
    details: '/employees/view/:id',
  },
  employeeGroups: {
    list: '/groups',
    details: '/groups/view/:id',
  },
  benefits: {
    list: '/benefits/all',
    details: '/benefits/view/:id',
  },
  alternatives: {
    list: '/alternatives',
    details: '/alternatives/:id',
  },
  news: {
    list: '/news',
    details: '/news/:id',
    categories: {
      create: '/news/categories/create',
      details: '/news/categories/:id',
    },
  },
  recognitions: {
    list: '/recognition/list',
    settings: '/recognition/settings',
    achievements: {
      list: '/recognition/achievements/list',
      details: '/recognition/achievements/:id',
      create: '/recognition/achievements/create',
    },
    allocations: {
      list: '/recognition/allocations',
    },
    givers: {
      list: '/recognition/givers',
    },
    recipients: {
      list: '/recognition/recipients',
    },
    leaderboard: {
      list: '/recognition/leaderboard',
    },
    rewards: {
      list: '/recognition/rewards',
    },
    rules: {
      list: '/recognition/rules',
    },
  },
  trustBox: {
    list: '/trust-box',
  },
  surveys: {
    list: '/surveys',
    details: '/surveys/:id',
    results: '/surveys/:id/results',
  },
  wallets: {
    list: '/wallet',
  },
  logs: {
    list: '/more/log',
  },
  automations: {
    list: '/automations',
    details: '/automations/:id',
    create: '/automations/create',
  },
} as const;
