import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './recognition-achievements.loaders';
import { recognitionAchievementsKeys } from './recognition-achievements.queries';

export const useUpdateRecognitionAchievement = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateAchievement, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionAchievementsKeys.lists);
    },
  });
};

export const useRemoveRecognitionAchievement = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.removeAchievement, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionAchievementsKeys.lists);
    },
  });
};

export const useUpdateAchievementsPriorities = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateAchievementsPriorities, {
    onSuccess: () => {
      return queryClient.invalidateQueries(recognitionAchievementsKeys.lists);
    },
  });
};
