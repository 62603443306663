import { Datepicker, MpTableDataArguments } from '@mp-react/table';
import create from 'zustand';
import { TableVariables } from '../types/Table';

export type HeaderVariant =
  | ''
  | 'groups'
  | 'innerEmployeeAssignGroups'
  | 'innerEmployeeTermsAcceptance'
  | 'termsAcceptance'
  | 'dashboard'
  | 'innerBenefitTermsAcceptance'
  | 'common'
  | 'companySettingsAdministrators'
  | 'companySettingsCompanies'
  | 'companySettingsCompanyGroups'
  | 'employeeCategories'
  | 'clientAdministrators'
  | 'recipients';

type UseTableStore = {
  variant: HeaderVariant;
  setVariant: (variant: HeaderVariant) => void;
  searchQuery: string;
  datepickerValue: string;
  variables: TableVariables;
  customColumn: string | null;
  isOpenCustomColumnDialog: boolean;
  setVariables: (newVariables: MpTableDataArguments) => void;
  setDatepickerVariables: (dates: Datepicker) => void;
  setSearch: (searchQuery: string) => void;
  setDatepickerValue: (date: string) => void;
  setCustomColumn: (customColumn: string | null) => void;
  setIsOpenCustomColumnDialog: (open: boolean) => void;
};

export const useTableStore = create<UseTableStore>((set) => ({
  variant: '',
  setVariant: (variant: HeaderVariant) => set(() => ({ variant })),
  searchQuery: '',
  variables: { columns: [] },
  datepickerValue: '',
  setVariables: (newVariables: MpTableDataArguments) =>
    set(({ variables }) => ({ variables: { ...variables, ...newVariables } })),
  setDatepickerVariables: (dates: Datepicker) =>
    set(({ variables }) => ({ variables: { ...variables, ...dates } })),
  setSearch: (searchQuery: string) =>
    set(() => ({
      searchQuery,
    })),
  setDatepickerValue: (datepickerValue: string) =>
    set(() => ({
      datepickerValue,
    })),
  customColumn: null,
  setCustomColumn: (customColumn: string | null) => {
    set(() => ({ customColumn }));
  },
  isOpenCustomColumnDialog: false,
  setIsOpenCustomColumnDialog: (open: boolean) =>
    set(() => ({ isOpenCustomColumnDialog: open })),
}));
