import { z } from 'zod';
import { paginationFiltersSchema } from 'utils/schemas';
import { voucherStatuses } from './admin-shop-vouchers.types';

export const adminShopVouchersFiltersSchema = z
  .object({
    search: z.string().catch(''),
    status: z.array(z.enum(voucherStatuses)).catch([]),
    prices: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);
