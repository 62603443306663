import { Typography } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ListTotal: FC<{ total?: number }> = ({ total = 0 }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h3">
      {t('common.allRowsCount', { count: total })}
    </Typography>
  );
};
