import { TextField } from 'melp-design/components';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputAdornmentProps,
  Stack,
} from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { Close } from 'melp-design/icons';
import { ComponentProps, forwardRef, useEffect, useState } from 'react';

const areEqual = (a: Moment, b: Moment | null) => a.isSame(b, 'day');

interface CustomInputAdornmentProps extends InputAdornmentProps {
  clearButtonProps?: ComponentProps<typeof IconButton>;
}

const CustomInputAdornment = ({
  clearButtonProps,
  children,
  ...rest
}: CustomInputAdornmentProps) => (
  <InputAdornment {...rest}>
    {clearButtonProps && (
      <IconButton {...clearButtonProps}>
        <Close />
      </IconButton>
    )}
    {children}
  </InputAdornment>
);

interface LocalStateDatePickerProps
  extends Omit<DatePickerProps<Moment>, 'value' | 'onChange'> {
  /**
   * Date picker allows to set only date (without time), but under the hood it
   * relies on date with time. This property allows to set the time part for a
   * date.
   *
   * 1) current - sets the time to the moment when date was selected.
   * 2) startOfDay - sets the time to 00:00:00:000. Default.
   * 3) endOfDay - sets the time to 23:59:59:999
   * 4) none - removes time entirely (e.g., "1991-06-27T00:00:00:000" will become "1991-06-27").
   *    This option is useful when only date is required (e.g., date of birth).
   */
  setTime?: 'current' | 'startOfDay' | 'endOfDay' | 'none';
}

interface Props {
  datePickerProps?: LocalStateDatePickerProps;
  value: string | null;
  onChange: (value: string | null) => void;
  label?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
}

export const DateInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      required,
      value,
      onChange,
      label,
      name,
      disabled,
      error,
      datePickerProps: { setTime, ...propsRest } = {},
    },
    forwardedRef,
  ) => {
    const [localValue, setLocalValue] = useState(value ? moment(value) : null);

    const handleChange = (newValue: typeof localValue) => {
      if (!newValue) {
        if (!!value) {
          // local date changed to null
          onChange(null);
        }
      } else if (newValue.isValid()) {
        if (!areEqual(newValue, moment(value))) {
          let timeAdjustedValue = newValue;

          if (setTime === 'startOfDay') {
            timeAdjustedValue = timeAdjustedValue.startOf('day');
          }

          if (setTime === 'endOfDay') {
            timeAdjustedValue = timeAdjustedValue.endOf('day');
          }

          if (setTime === 'current') {
            const now = moment();
            timeAdjustedValue = timeAdjustedValue.set({
              hour: now.get('hour'),
              minute: now.get('minute'),
              second: now.get('second'),
              millisecond: now.get('millisecond'),
            });
          }

          let newValueAsString = timeAdjustedValue.toISOString(true);

          if (setTime === 'none') {
            newValueAsString = moment(newValueAsString).format('YYYY-MM-DD');
          }

          // local date has changed compared to form date
          onChange(newValueAsString);
        }
      } else {
        setLocalValue(newValue);
      }
    };

    useEffect(() => {
      // both equal to null
      if ((value ?? null) === localValue) {
        return;
      }
      // form state value became null
      if (value === null) {
        setLocalValue(null);
        return;
      }
      const valueAsMoment = moment(value);
      // form state value is the same as local state value
      if (areEqual(valueAsMoment, localValue)) {
        return;
      }
      // form state value differs from local state value
      setLocalValue(valueAsMoment);
      // this side effect should react only to form date value
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const clearDates = () => {
      onChange(null);
      setLocalValue(null);
    };

    const handleInputBlur = () => {
      if (localValue === null || localValue.isValid()) {
        return;
      }
      if (value !== null) {
        onChange(null);
      } else {
        setLocalValue(null);
      }
    };

    return (
      <FormControl
        error={!!error}
        name={name}
        disabled={disabled}
        component="fieldset"
      >
        <Stack direction="row" alignItems="center">
          <DatePicker
            slots={{
              // Currently there is no good option to properly override the text field
              textField: TextField as any,
              ...(disabled ? {} : { inputAdornment: CustomInputAdornment }),
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!error,
                required,
                onBlur: handleInputBlur,
              },
              inputAdornment: {
                clearButtonProps:
                  !!value || !!localValue
                    ? {
                        onClick: clearDates,
                      }
                    : undefined,
              } as any, // currently there is no good way to extend InputAdornment
            }}
            sx={{
              '.MuiOutlinedInput-input, .MuiOutlinedInput-input::placeholder': {
                textTransform: 'lowercase',
              },
            }}
            label={label}
            value={localValue}
            onChange={handleChange}
            disabled={disabled}
            inputRef={forwardedRef}
            {...propsRest}
          />
        </Stack>
        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl>
    );
  },
);

DateInput.displayName = 'DateInput';
