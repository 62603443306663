import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './recognition-achievements.loaders';
import { converters } from './recognition-achievements.converters';

export const recognitionAchievementsKeys = queryKeyFactory(
  'recognition-achievements',
);

export const useRecognitionAchievements = () => {
  return useQuery({
    queryKey: recognitionAchievementsKeys.list(),
    queryFn: () => loaders.getAchievements(),
    select: converters.getAchievements,
  });
};
