import { converters as tableConverters } from 'store/table';
import { converters as filesConverters } from 'store/files';
import { IAdminDiscount, IBaseAdminDiscount } from 'types/income';
import { loaders } from './admin-discounts.loaders';
import { AdminDiscount, BaseAdminDiscount } from './admin-discounts.types';

const convertBaseDiscount = (
  discount: IBaseAdminDiscount,
): BaseAdminDiscount => {
  return {
    id: discount.id,
    name: discount.name,
    supplier: { id: discount.supplierId, name: discount.supplierName },
    priority: discount.priority,
    validTo: discount.validTo,
    status: discount.status,
    publishStatus: discount.isDraft ? 'unpublished' : 'published',
    viewsCount: discount.viewsCount,
    uniqueViewsCount: discount.uniqueViewsCount,
    favoritesCount: discount.isFavouriteCount,
    packages: discount.discountPackage,
  };
};

const convertDiscount = (discount: IAdminDiscount): AdminDiscount => {
  return {
    ...convertBaseDiscount(discount),
    categories: discount.categories,
    codeImage: discount.qrBarCodeImage
      ? filesConverters.getUploadedFile(discount.qrBarCodeImage)
      : null,
    image: discount.discountImage
      ? filesConverters.getUploadedFile(discount.discountImage)
      : null,
    comment: discount.internalComment,
    companies: discount.discountPackageClientIds,
    countries: discount.countries,
    link: discount.discountLink,
    code: discount.discountCode,
    isSupplierContactPublic: discount.showSupplierContactForUsers,
    createdAt: discount.creationDate,
    validFrom: discount.validFrom,
    supplierContact: discount.supplierContact,
    publishedAt: discount.publishDateTime,
    isUniqueCode: discount.isUniqueCode,
    revealFrequencyLimit: discount.revealFrequencyLimit,
    searchTags: discount.searchTags ?? '',
    showCurrentTime: discount.showCurrentTime,
    translations: discount.translations.map((trans) => ({
      language: trans.language,
      title: trans.discountTitle,
      teaser: trans.teaserLine,
      promo: trans.discountPromo,
      description: trans.discountDescription,
      attachments: trans.attachments.map((attachment) =>
        filesConverters.getUploadedFile(attachment),
      ),
    })),
  };
};

const convertDiscounts = (
  discounts: IBaseAdminDiscount[],
): BaseAdminDiscount[] => {
  return discounts.map(convertBaseDiscount);
};

export const converters = {
  getDiscounts: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDiscounts>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertDiscounts(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDiscount: ({ data }: Awaited<ReturnType<typeof loaders.getDiscount>>) => {
    return convertDiscount(data);
  },
};
