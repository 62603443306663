import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { Translations } from 'types/Common';
import { IBaseNewItem, INewItem, INewsCategory } from 'types/income';
import { TableDataResponse } from 'types/Table';
import { APP } from 'config';
import { exportXlsxConfig, getBinaryValue } from 'utils/general';
import {
  NewsCategoriesFilters,
  NewsCategoryTranslations,
  NewsFilters,
  NewsSendMethod,
} from './news.types';

interface GetNews {
  filters: Partial<NewsFilters>;
}

interface GetNew {
  newId: string;
}

interface CreateNew {
  name: string;
}

export interface UpdateNew {
  newId: string;
  update: Partial<{
    name: string;
    publishedAt: string | null;
    inactiveAt: string | null;
    sendMethods: NewsSendMethod[];
    draft: boolean;
    translations: Translations<{
      title: string;
      content: string;
      headerImageId: string | null;
      attachmentIds: string[];
    }>;
    pinned: boolean;
    categories: string[];
  }>;
}

interface CopyNew {
  newId: string;
  name: string;
}

interface RemoveNew {
  newId: string;
}

interface ExportXls {
  filters: Partial<NewsFilters>;
}

interface GetCategories {
  filters: Partial<NewsCategoriesFilters>;
}

interface GetCategory {
  categoryId: string;
}

interface CreateCategory {
  name: string;
  translations: NewsCategoryTranslations;
}

interface UpdateCategory {
  categoryId: string;
  name: string;
  translations: NewsCategoryTranslations;
}

interface DeleteCategory {
  categoryId: string;
}

export const loaders = {
  getNews: ({ filters }: GetNews) => {
    return api<TableDataResponse<IBaseNewItem>>({
      url: Endpoints.clientAdmin.news.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          status: filters.status,
          publishDateFrom: filters.publishedAt?.from,
          publishDateTo: filters.publishedAt?.to,
          categoryIds: filters.categories,
          employeeGroupIds: filters.employeeGroups,
          notificationMethods: filters.sendMethods,
          pinned: getBinaryValue(filters.pinned),
        },
      },
    });
  },
  getNew: ({ newId }: GetNew) => {
    return api<INewItem>({
      url: Endpoints.clientAdmin.news.byId.root.replace(':newId', newId),
    });
  },
  createNew: ({ name }: CreateNew) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.news.create,
      method: 'post',
      data: { name },
    });
  },
  updateNew: ({ newId, update }: UpdateNew) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.news.byId.root.replace(':newId', newId),
      method: 'patch',
      data: {
        name: update.name,
        draft: update.draft,
        pinned: update.pinned,
        inactiveDate: update.inactiveAt,
        publishDate: update.publishedAt,
        sendMethods: update.sendMethods,
        translations: update.translations,
        categoryIds: update.categories,
      },
    });
  },
  copyNew: ({ newId, name }: CopyNew) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.news.byId.copy.replace(':newId', newId),
      params: { name },
      method: 'post',
    });
  },
  deleteNew: ({ newId }: RemoveNew) => {
    return api({
      url: Endpoints.clientAdmin.news.byId.root.replace(':newId', newId),
      method: 'delete',
    });
  },
  exportXls: ({ filters }: ExportXls) => {
    return api({
      url: Endpoints.clientAdmin.news.exportToExcel,
      params: {
        filter: {
          status: filters.status,
          publishDateFrom: filters.publishedAt?.from,
          publishDateTo: filters.publishedAt?.to,
        },
      },
      ...exportXlsxConfig,
    });
  },
  getCategories: ({ filters }: GetCategories) => {
    return api<TableDataResponse<INewsCategory>>({
      url: Endpoints.clientAdmin.news.categories.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize ?? APP.pagination.defaults.pageSize[0],
      },
    });
  },
  getCategory: ({ categoryId }: GetCategory) => {
    return api<INewsCategory>({
      url: Endpoints.clientAdmin.news.categories.byId.replace(
        ':categoryId',
        categoryId,
      ),
    });
  },
  createCategory: ({ name, translations }: CreateCategory) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.news.categories.root,
      method: 'post',
      data: { name, translations },
    });
  },
  updateCategory: ({ categoryId, name, translations }: UpdateCategory) => {
    return api<{ id: string }>({
      url: Endpoints.clientAdmin.news.categories.byId.replace(
        ':categoryId',
        categoryId,
      ),
      method: 'put',
      data: { name, translations },
    });
  },
  deleteCategory: ({ categoryId }: DeleteCategory) => {
    return api({
      url: Endpoints.clientAdmin.news.categories.byId.replace(
        ':categoryId',
        categoryId,
      ),
      method: 'delete',
    });
  },
};
