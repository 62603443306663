import { z } from 'zod';
import { Currency } from 'types/general';
import { adminShopVouchersFiltersSchema } from './admin-shop-vouchers.schemas';

export const voucherStatuses = [
  'available',
  'archived',
  'expired',
  'sold',
] as const;
export type VoucherStatus = (typeof voucherStatuses)[number];

export interface RedeemMethods {
  qrCode: string;
  barCode: string;
  serial: string;
  url: string;
  code: string;
  pin: string;
}

export interface AdminShopVoucher {
  id: string;
  shopItem: {
    id: string;
    priceId: string;
  };
  order: {
    id: string;
    name: string;
  } | null;
  sequence: number;
  status: VoucherStatus;
  price: number;
  purchasePrice: number;
  purchaseReference: string;
  currency: Currency;
  redeem: RedeemMethods;
  fileLink: string;
  createdAt: string;
  expiresAt: string | null;
  lastAccess: string | null;
}

export type AdminShopVouchersFilters = z.infer<
  typeof adminShopVouchersFiltersSchema
>;
