import { converters as tableConverters } from 'store/table';
import { IRecognitionLeaderboardEmployee } from 'types/income';
import { loaders } from './recognition-leaderboard.loaders';
import { RecognitionLeaderboardEmployee } from './recognition-leaderboard.types';

const convertLeaderboardEmployee = (
  employee: IRecognitionLeaderboardEmployee,
): RecognitionLeaderboardEmployee => {
  return {
    id: employee.id,
    fullName: employee.fullName,
    receivedPoints: employee.receivedPoints,
    rewardedPoints: employee.rewardedPoints,
    totalPoints: employee.totalPoints,
  };
};

const convertLeaderboardEmployees = (
  employees: IRecognitionLeaderboardEmployee[],
): RecognitionLeaderboardEmployee[] => {
  return employees.map(convertLeaderboardEmployee);
};

export const converters = {
  getLeaderboard: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getLeaderboard>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertLeaderboardEmployees(data.data),
      meta: {
        receivedPoints: data.receivedPointsCount,
        rewardedPoints: data.rewardedPointsCount,
        totalPoints: data.totalPointsCount,
      },
      total,
      page,
      pageSize,
      pages,
    };
  },
};
